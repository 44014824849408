import { InnerFullVideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { animation, getThemeOptions, themeSelector } from "@schemas/presets";

const schema: Schema.Module<InnerFullVideoProps> = {
	schemaType: "module",
	component: "InnerFullVideo",
	displayName: "Full Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Source",
					type: "ConditionalField",
					key: "source",
					mandatory: true,
					options: [
						{
							name: "url",
							value: "url",
							title: "Video (from URL)",
						},
					],
					fields: [
						{
							condition: "url",
							type: "TextField",
							key: "backgroundVideoURL",
							title: "Background video URL",
							validators: { format: "URL" },
							mandatory: true,
							helptext:
								"This video will be used as a background video. It will be muted and looped.",
						},
						{
							condition: "url",
							type: "ImageField",
							key: "backgroundImage",
							title: "Background video Image",
							mandatory: true,
							helptext: "This will be shown as a background.",
						},
						{
							condition: "url",
							title: "Open video in a modal",
							type: "ConditionalField",
							key: "openInModal",
							mandatory: true,
							options: [
								{
									name: "yes",
									value: true,
									title: "Yes",
								},

								{
									name: "no",
									value: false,
									title: "No",
								},
							],
							fields: [
								{
									condition: true,
									type: "TextField",
									key: "buttonText",
									title: "Button text",
									mandatory: true,
									humanReadable: true,
								},
								{
									condition: true,
									title: "Video to show in modal",
									type: "ConditionalField",
									key: "differentModalVideo",
									mandatory: true,
									options: [
										{
											name: "samevideo",
											value: false,
											title: "Same Video",
										},
										{
											name: "different",
											value: true,
											title: "Different Video",
										},
									],
									fields: [
										{
											condition: true,
											type: "TextField",
											key: "modalVideoURL",
											title: "Modal video URL",
											validators: { format: "URL" },
											mandatory: true,
										},
									],
								},
							],
						},
					],
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					mandatory: true,
					step: 10,
					min: 0,
					max: 100,
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...themeSelector, options: getThemeOptions(["default", "inverse"]) },
				{ ...animation },
			],
		},
	],

	default: {
		component: "InnerFullVideo",
		backgroundVideoURL: "",
		backgroundImage: undefined,
		openInModal: true,
		differentModalVideo: false,
		modalVideoURL: "",
		buttonText: "Ver Vídeo",
		veil: 30,
		subtheme: "inverse",
		animation: "none",
		source: "url",
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FullVideo/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FullVideo/thumbnail@2x.png",
	},
};

export default schema;
