import { HeroVideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	anchorID,
	themeSelector,
	loremIpsumParagraph,
	content,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<HeroVideoProps> = {
	schemaType: "module",
	component: "HeroVideo",
	category: "heros",
	displayName: "Hero Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					hideable: false,
					isMockup: true,
				},
				{
					...content,
				},
				{
					title: "Source",
					type: "ConditionalField",
					key: "source",
					mandatory: true,
					options: [
						{
							name: "url",
							value: "url",
							title: "Video (from URL)",
						},
					],
					fields: [
						{
							condition: "url",
							title: "Background Video URL",
							type: "TextField",
							key: "videoUrl",
							mandatory: true,
							helptext:
								"This video will be used as a background video. It will be muted and looped. Playlist URL are not allowed.",
						},
						{
							condition: "url",
							title: "Background Video Image",
							type: "ImageField",
							key: "imageBackground",
							mandatory: true,
							helptext: "It will be shown as background in mobile devices.",
						},
						{
							condition: "url",
							key: "openVideoInModal",
							title: "Open video in a modal?",
							type: "ConditionalField",
							mandatory: true,
							options: [
								{
									name: "yes",
									value: true,
									title: "Yes",
								},
								{
									name: "no",
									value: false,
									title: "No",
								},
							],
							fields: [
								{
									condition: true,
									type: "TextField",
									title: "Button Text",
									mandatory: true,
									key: "buttonText",
									placeholder: "Type a placeholder text",
									humanReadable: true,
								},
								{
									condition: true,
									key: "videoToShow",
									title: "Video to show in modal",
									type: "ConditionalField",
									mandatory: true,
									options: [
										{
											name: "same video",
											value: "same",
											title: "Same Video",
										},
										{
											name: "different video",
											value: "different",
											title: "Different Video",
										},
									],
									fields: [
										{
											condition: "different",
											title: "Modal Video URL",
											type: "TextField",
											key: "modalVideoUrl",
											mandatory: true,
										},
									],
								},
							],
						},
					],
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{ ...themeSelector, options: getThemeOptions(["default", "inverse"]) },
				{
					type: "UniqueCheck",
					key: "breadcrumbsDesktop",
					title: "Breadcrumbs Desktop/Tablet",
					options: [{ title: "Display in tablet and desktop" }],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbs",
					title: "Breadcrumbs Mobile",
					options: [{ title: "Display in mobile" }],
				},
			],
		},
	],

	thumbnails: {
		"1x": "/thumbnails/modules/HeroVideo/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroVideo/thumbnail@2x.png",
	},

	default: {
		component: "HeroVideo",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		content: loremIpsumParagraph,
		buttonText: "Ver vídeo",
		videoType: "video",
		videoUrl: "",
		imageBackground: undefined,
		videoDecorationUrl: "",
		openVideoInModal: true,
		videoToShow: "same",
		modalVideoUrl: "",
		veil: 30,
		anchorID: null,
		subtheme: "inverse",
		breadcrumbsDesktop: false,
		breadcrumbs: false,
		source: "url",
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
		},
	},
};

export default schema;
