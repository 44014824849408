import { SocialShareProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	getThemeOptions,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<SocialShareProps> = {
	component: "SocialShare",
	schemaType: "module",
	category: "social",
	displayName: "Social Share",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false, isMockup: true },
				{
					title: "Social Media",
					type: "CheckGroup",
					key: "socialMedia",
					options: [
						{ name: "facebook", title: "Facebook" },
						{ name: "linkedin", title: "LinkedIn" },
						{ name: "twitter", title: "X (Twitter)" },
						{ name: "email", title: "Email" },
					],
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "SocialShare",
		title: { tag: "h2", content: "Lorem ipsum" },
		socialMedia: ["linkedin"],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/SocialShare/thumbnail@1x.png",
		"2x": "/thumbnails/modules/SocialShare/thumbnail@2x.png",
	},
};

export default schema;
