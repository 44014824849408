import { ProgramLandingProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import LandingForm from "@schemas/components/LandingForm";
import AnimatedImageGallery from "@schemas/modules/AnimatedImageGallery";
import BasicContent from "@schemas/modules/BasicContent";
import BasicText from "@schemas/modules/BasicText";
import CardsCarousel from "@schemas/modules/CardsCarousel";
import FeaturedContent from "@schemas/modules/FeaturedContent";
import HeroLanding from "@schemas/modules/HeroLanding";
import HighlightedContent from "@schemas/modules/HighlightedContent";
import LandingProgramsDistributor from "@schemas/modules/LandingProgramsDistributor";
import LandingQuickFacts from "@schemas/modules/LandingQuickFacts";
import Quote from "@schemas/modules/Quote";
import TextCarousel from "@schemas/modules/TextCarousel";

const schema: Schema.Template<ProgramLandingProps> = {
	schemaType: "template",
	displayName: "Program Landing",
	component: "ProgramLanding",
	dataPacks: ["PROGRAMS"],

	type: { label: "Static", value: "static" },

	dimensions: [],

	content: [
		{
			type: "TextField",
			key: "landingCode",
			title: "Campaign code (com_codigopublicidad in CRM)",
			mandatory: true,
		},
		{
			title: "Program",
			type: "ReferenceField",
			sources: [{ structuredData: "PROGRAM" }],
			selectionType: ["manual"],
			key: "data",
			maxItems: 1,
			mandatory: true,
		},
		{
			type: "ComponentContainer",
			title: "Form",
			key: "form",
			whiteList: ["LandingForm"],
		},
		{
			key: "document",
			title: "Brochure",
			type: "FileField",
			placeholder: "Upload a program brochure",
			hideable: true,
		},
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["HeroLanding"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Quick Facts Section",
			maxItems: 1,
			whiteList: ["LandingQuickFacts"],
			contentType: "modules",
			key: "quickFactsSection",
		},
		{
			type: "ComponentArray",
			title: "Main Section",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"ContactInfo",
				"CypherCollection",
				"DownloadCollection",
				"FAQCollection",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"FeaturedFacts",
				"FullVideo",
				"HighlightedCardCollection",
				"HorizontalTabs",
				"ImageBanner",
				"ImageGallery",
				"IntroForm",
				"LogoCollection",
				"Milestones",
				"Quote",
				"SocialShare",
				"Spacer",
				"StepCollection",
				"StickyTabs",
				"Table",
				"VerticalTabs",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
				"TextCarousel",
				"HighlightedContent",
				"AnimatedImageGallery",
				"CardsCarousel",
			],
			contentType: "modules",
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "ProgramLanding",
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
			fullRelations: true,
		},
		form: { ...LandingForm.default },
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...HeroLanding.default }],
		},
		quickFactsSection: {
			component: "Section",
			name: "Quick Facts Section",
			modules: [{ ...LandingQuickFacts.default }],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{ ...BasicContent.default },
				{ ...TextCarousel.default, subtheme: "accent" },
				{ ...AnimatedImageGallery.default },
				{ ...HighlightedContent.default, subtheme: "default" },
				{ ...LandingProgramsDistributor.default },
				{ ...CardsCarousel.default },
				{ ...BasicText.default },
				{ ...Quote.default, subtheme: "accent" },
				{ ...FeaturedContent.default },
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProgramLanding/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProgramLanding/thumbnail@2x.png",
	},
};

export default schema;
