import { BackgroundVideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<BackgroundVideoProps> = {
	schemaType: "component",
	component: "BackgroundVideo",
	displayName: "Background Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Source",
					type: "ConditionalField",
					key: "source",
					mandatory: true,
					options: [
						{
							name: "url",
							value: "url",
							title: "Video (from URL)",
						},
					],
					fields: [
						{
							condition: "url",
							title: "Background Video URL",
							type: "TextField",
							key: "videoUrl",
							mandatory: true,
							helptext:
								"This video will be used as a background video. It will be muted and looped. Playlist URL are not allowed.",
						},
						{
							condition: "url",
							title: "Background Video Image",
							type: "ImageField",
							key: "imageBackground",
							mandatory: true,
							helptext: "It will be shown as background in mobile devices.",
						},
					],
				},
			],
		},

		{
			title: "Config",
			fields: [
				{
					type: "UniqueCheck",
					title: "Use veil",
					key: "useVeil",
					options: [
						{
							title: "Use veil",
						},
					],
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
				},
			],
		},
	],

	default: {
		component: "BackgroundVideo",
		useVeil: true,
		veil: 20,
		source: "url",
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/BackgroundVideo/thumbnail@1x.png",
		"2x": "/thumbnails/components/BackgroundVideo/thumbnail@2x.png",
	},
};

export default schema;
