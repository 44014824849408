//
// Griddo Templates
//
// Every Template must be imported and reexported.
// Yes, a barrel index :)

import * as React from "react";

// Templates
const BasicTemplate = React.lazy(() => import("./BasicTemplate"));
const Error404 = React.lazy(() => import("./Error404"));
const EventDetail = React.lazy(() => import("./EventDetail"));
const EventsList = React.lazy(() => import("./EventsList"));
const Landing = React.lazy(() => import("./Landing"));
const NewsDetail = React.lazy(() => import("./NewsDetail"));
const NewsList = React.lazy(() => import("./NewsList"));
const PressReleasesDetail = React.lazy(() => import("./PressReleasesDetail"));
const PressReleasesList = React.lazy(() => import("./PressReleasesList"));
const ProgramDetail = React.lazy(() => import("./ProgramDetail"));
const ProgramLanding = React.lazy(() => import("./ProgramLanding"));
const ProgramsList = React.lazy(() => import("./ProgramsList"));
const SearchResults = React.lazy(() => import("./SearchResults"));

// Griddo bundle export
export default {
	BasicTemplate,
	Error404,
	EventDetail,
	EventsList,
	Landing,
	NewsDetail,
	NewsList,
	PressReleasesDetail,
	PressReleasesList,
	ProgramDetail,
	ProgramLanding,
	ProgramsList,
	SearchResults,
};
