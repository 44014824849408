import { LandingProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import LandingForm from "@schemas/components/LandingForm";
import AnimatedImageGallery from "@schemas/modules/AnimatedImageGallery";
import BasicContent from "@schemas/modules/BasicContent";
import CardCollection from "@schemas/modules/CardCollection";
import CardsCarousel from "@schemas/modules/CardsCarousel";
import HeroLanding from "@schemas/modules/HeroLanding";
import HighlightedContent from "@schemas/modules/HighlightedContent";
import LandingProgramsDistributor from "@schemas/modules/LandingProgramsDistributor";
import TextCarousel from "@schemas/modules/TextCarousel";

const schema: Schema.Template<LandingProps> = {
	schemaType: "template",
	displayName: "Landing",
	component: "Landing",
	type: { label: "Static", value: "static" },

	dimensions: [],

	content: [
		{
			type: "TextField",
			key: "landingCode",
			title: "Campaign code (com_codigopublicidad in CRM)",
			mandatory: true,
		},
		{
			type: "ConditionalField",
			key: "landingType",
			title: "Landing type",
			options: [
				{
					title: "Program types + Academic areas selectors",
					name: "programTypeAndAreas",
					value: "programTypeAndAreas",
				},
				{
					title: "Programs selector",
					name: "programs",
					value: "programs",
				},
			],
			fields: [
				{
					title: "Program",
					type: "ReferenceField",
					sources: [{ structuredData: "PROGRAM" }],
					key: "data",
					condition: "programs",
					mandatory: true,
				},
				{
					title: "Program types",
					type: "ReferenceField",
					sources: [{ structuredData: "PROGRAM_TYPE" }],
					key: "dataTypes",
					condition: "programTypeAndAreas",
					mandatory: true,
				},
				{
					title: "Academic areas",
					type: "ReferenceField",
					sources: [{ structuredData: "ACADEMIC_AREA" }],
					key: "dataAreas",
					condition: "programTypeAndAreas",
					mandatory: true,
				},
			],
			mandatory: true,
		},
		{
			type: "ComponentContainer",
			title: "Form",
			key: "form",
			whiteList: ["LandingForm"],
		},
		{
			key: "document",
			title: "Brochure",
			type: "FileField",
			placeholder: "Upload a program brochure",
			hideable: true,
		},
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["HeroLanding"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"ContactInfo",
				"CypherCollection",
				"DownloadCollection",
				"FAQCollection",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"FeaturedFacts",
				"FullVideo",
				"HighlightedCardCollection",
				"HorizontalTabs",
				"ImageBanner",
				"ImageGallery",
				"IntroForm",
				"LogoCollection",
				"Milestones",
				"Quote",
				"SocialShare",
				"Spacer",
				"StepCollection",
				"StickyTabs",
				"Table",
				"VerticalTabs",
				"Widget",
				"Wysiwyg",
				"TextCarousel",
				"HighlightedContent",
				"AnimatedImageGallery",
				"CardsCarousel",
			],
			contentType: "modules",
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "Landing",
		landingType: "programTypeAndAreas",
		hasDistributorData: true,
		data: {
			mode: "manual",
			fullRelations: true,
		},
		dataTypes: {
			mode: "auto",
			sources: [{ structuredData: "PROGRAM_TYPE" }],
			fullRelations: true,
		},
		dataAreas: {
			mode: "auto",
			sources: [{ structuredData: "ACADEMIC_AREA" }],
			fullRelations: true,
		},
		form: { ...LandingForm.default },
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [{ ...HeroLanding.default }],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{ ...BasicContent.default },
				{ ...TextCarousel.default, subtheme: "accent" },
				{ ...LandingProgramsDistributor.default, subtheme: "default-alt" },
				{ ...AnimatedImageGallery.default, subtheme: "default-alt" },
				{ ...CardCollection.default },
				{ ...CardsCarousel.default },
				{ ...HighlightedContent.default },
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/Landing/thumbnail@1x.png",
		"2x": "/thumbnails/templates/Landing/thumbnail@2x.png",
	},
};

export default schema;
