import { Schema } from "@griddo/core";

const schema: Schema.MenuItem = {
	link: {
		fields: [
			{
				title: "Icon",
				type: "Select",
				key: "icon",
				placeholder: "Select an icon",
				options: [
					{ value: "lock", label: "Lock" },
					{ value: "user2", label: "User" },
					{ value: "add", label: "Add" },
					{ value: "email", label: "Envelope" },
				],
			},
			{
				type: "ToggleField",
				title: "",
				key: "special",
				auxText: "Check it if the link has a left separator.",
				helptext: "",
				background: true,
			},
		],
	},

	group: {
		fields: [
			{
				title: "Icon",
				type: "Select",
				key: "icon",
				placeholder: "Select an icon",
				options: [
					{ value: "lock", label: "Lock" },
					{ value: "user2", label: "User" },
					{ value: "add", label: "Add" },
					{ value: "email", label: "Envelope" },
				],
			},
			{
				type: "ToggleField",
				title: "",
				key: "special",
				auxText: "Check it if the link has a left separator.",
				helptext: "",
				background: true,
			},
		],
	},
};

export default schema;
