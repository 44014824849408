import { ProgramDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicContent from "@schemas/modules/BasicContent";
import CypherCollection from "@schemas/modules/CypherCollection";
import DownloadCollection from "@schemas/modules/DownloadCollection";
import FAQCollection from "@schemas/modules/FAQCollection";
import PeopleDistributor from "@schemas/modules/PeopleDistributor";
import ProgramIntroForm from "@schemas/modules/ProgramIntroForm";
import ProgramNavigation from "@schemas/modules/ProgramNavigation";
import Quote from "@schemas/modules/Quote";
import StepCollection from "@schemas/modules/StepCollection";
import StudyPlan from "@schemas/modules/StudyPlan";
import TestimonialDistributor from "@schemas/modules/TestimonialDistributor";
import VerticalTabs from "@schemas/modules/VerticalTabs";
import { additional, heading } from "@schemas/presets";

const schema: Schema.Template<ProgramDetailProps> = {
	schemaType: "template",
	component: "ProgramDetail",
	displayName: "Program",
	dataPacks: ["PROGRAMS"],

	type: {
		label: "Program",
		value: "program",
		mode: "detail",
	},

	content: [
		{
			...heading,
			title: "Hero title",
			key: "heroTitle",
			default: { content: "Lorem ipsum", tag: "h1" },
		},
		{ ...additional },
		{
			type: "Wysiwyg",
			full: false,
			title: "Abstract",
			key: "abstract",
			hideable: true,
			isMockup: true,
			placeholder: "Type an abstract text",
			humanReadable: true,
		},
		{
			type: "ImageField",
			title: "Image",
			key: "image",
			hideable: true,
		},
		{
			title: "Document to download",
			type: "ReferenceField",
			key: "data",
			sources: [{ structuredData: "DOCUMENT" }],
			selectionType: ["manual"],
			maxItems: 1,
		},
		{
			type: "ComponentArray",
			title: "Navigation",
			key: "navigation",
			whiteList: ["ProgramNavigation"],
			contentType: "modules",
			maxItems: 1,
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Main Content",
			key: "mainSection",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"ContactInfo",
				"CypherCollection",
				"DownloadCollection",
				"FAQCollection",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"FeaturedFacts",
				"FullVideo",
				"HighlightedCardCollection",
				"HighlightedContent",
				"HorizontalTabs",
				"ImageBanner",
				"ImageGallery",
				"ProgramIntroForm",
				"LogoCollection",
				"Milestones",
				"Quote",
				"SocialShare",
				"StepCollection",
				"StickyTabs",
				"Spacer",
				"Table",
				"VerticalTabs",
				"TextCarousel",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			mandatory: true,
		},
		{
			type: "FieldGroup",
			key: "quickFacts",
			title: "Quick facts",
			fields: [
				{
					type: "DateField",
					title: "Starts On",
					key: "intake",
					hideable: true,
				},
				{
					type: "TextField",
					title: "Duration",
					key: "duration",
					hideable: true,
				},
				{
					type: "TextField",
					title: "Seats",
					key: "seats",
					hideable: true,
				},
				{
					title: "",
					type: "MultiCheckSelectGroup",
					key: "categories",
					filled: true,
					elements: [
						{
							placeholder: "Centers",
							key: "centers",
							source: "CENTER",
						},
						{
							placeholder: "Schools",
							key: "schools",
							source: "SCHOOL",
						},
						{
							placeholder: "Program type",
							key: "programTypes",
							source: "PROGRAM_TYPE",
							mandatory: true,
						},
						{
							placeholder: "Academic area",
							key: "academicAreas",
							source: "ACADEMIC_AREA",
						},
						{
							placeholder: "Languages",
							key: "languages",
							source: "LANGUAGE",
						},
						{
							placeholder: "Modalities",
							key: "modalities",
							source: "MODALITY",
						},
					],
				},
			],
		},
	],

	config: [
		{
			type: "UniqueCheck",
			key: "breadcrumbsDesktop",
			title: "Breadcrumbs Desktop/Tablet",
			options: [{ title: "Display in tablet and desktop" }],
		},
		{
			type: "UniqueCheck",
			key: "breadcrumbs",
			title: "Breadcrumbs Mobile",
			options: [{ title: "Display in mobile" }],
		},
		{
			type: "RadioGroup",
			key: "titleSize",
			title: "Title Size",
			mandatory: true,
			options: [
				{ name: "s", value: "s", title: "s" },
				{ name: "m", value: "m", title: "m" },
				{ name: "l", value: "l", title: "l" },
				{ name: "xl", value: "xl", title: "xl" },
			],
		},
	],

	default: {
		type: "template",
		templateType: "ProgramDetail",
		heroTitle: { content: "", tag: "h1" },
		additional: null,
		navigation: {
			component: "Section",
			name: "Navigation",
			modules: [{ ...ProgramNavigation.default }],
		},
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{ ...ProgramIntroForm.default },
				{ ...BasicContent.default },
				{ ...CypherCollection.default, subtheme: "inverse" },
				{ ...StudyPlan.default },
				{ ...Quote.default, subtheme: "accent" },
				{ ...DownloadCollection.default },
				{ ...TestimonialDistributor.default },
				{ ...StepCollection.default },
				{ ...PeopleDistributor.default },
				{ ...VerticalTabs.default },
				{ ...FAQCollection.default, subtheme: "inverse" },
			],
		},
		titleSize: "m",
		breadcrumbsDesktop: true,
		breadcrumbs: false,
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProgramDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProgramDetail/thumbnail@2x.png",
	},
};

export default schema;
