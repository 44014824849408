import { EVENT_TYPE } from "./EVENT_TYPE";
import { FORMAT } from "./FORMAT";
import { LOCATION } from "./LOCATION";
import { MODALITY } from "./MODALITY";
import { NEWS_TYPE } from "./NEWS_TYPE";
import { SERVICE } from "./SERVICE";
import { TOPIC } from "./TOPIC";

export default {
	EVENT_TYPE,
	FORMAT,
	LOCATION,
	MODALITY,
	NEWS_TYPE,
	SERVICE,
	TOPIC,
};
