import { Schema } from "@griddo/core";

export const SCHOOL: Schema.SimpleContentType = {
	dataPacks: ["NEWS", "EVENTS", "PROGRAMS", "TESTIMONIAL"],
	title: "Schools & Chairs",
	local: true,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "logo",
				title: "Logo",
				type: "ImageField",
			},
		],
	},
};
