import { QuickFactProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<QuickFactProps> = {
	schemaType: "component",
	component: "QuickFact",
	displayName: "Quick Fact",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Label",
					type: "TextArea",
					key: "label",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Icon",
					type: "Select",
					key: "icon",
					placeholder: "Select an icon",
					mandatory: true,
					options: [
						{ value: "clock", label: "Clock" },
						{ value: "file", label: "File" },
						{ value: "book", label: "Book" },
						{ value: "copy", label: "Copy" },
						{ value: "user", label: "User" },
						{ value: "user2", label: "User 2" },
						{ value: "flag", label: "Flag" },
						{ value: "globe", label: "Globe" },
						{ value: "computer", label: "Computer" },
						{ value: "userLocation", label: "User location" },
						{ value: "duration", label: "Duration" },
						{ value: "label", label: "Label" },
						{ value: "bookmark", label: "Bookmark" },
						{ value: "creditCard", label: "Credit card" },
						{ value: "badge", label: "Badge" },
						{ value: "save", label: "Save" },
						{ value: "folder", label: "Folder" },
						{ value: "shield", label: "Shield" },
						{ value: "verifiedShield", label: "Verified shield" },
						{ value: "graduationCap", label: "Graduation cup" },
						{ value: "stage", label: "Stage" },
						{ value: "calendar", label: "Calendar" },
						{ value: "location", label: "Location" },
						{ value: "email", label: "Mail" },
						{ value: "phone", label: "Phone" },
						{ value: "chat", label: "Chat" },
						{ value: "chat2", label: "Chat 2" },
						{ value: "lock", label: "Lock" },
						{ value: "unlock", label: "Unlock" },
					],
				},
				{
					type: "TextField",
					key: "content",
					title: "Content",
					mandatory: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "QuickFact",
		label: "Lorem Ipsum",
		content: "Lorem Ipsum",
		icon: "clock",
	},
};

export default schema;
