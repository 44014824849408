import { Theme } from "@griddo/core";
import primitives from "@themes/global-theme/colors";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="accent"]',
	id: "accent",
	name: "The accent theme",
	primitives: [
		...primitives,

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#1510F7" }],
		},

		{
			id: "support",
			name: "support",
			values: [{ cssVar: "--success", value: "#8ECC0B" }],
		},

		// -------------------------------------------------------------------------
		// TEXT AND ICONS
		// -------------------------------------------------------------------------

		{
			id: "text",
			name: "text",
			values: [
				{ cssVar: "--text-1", value: "#FFFFFF" },
				{ cssVar: "--text-2", value: "#C9D8EA" },
				{ cssVar: "--text-3", value: "#9FAEFF" },
				{ cssVar: "--text-4", value: "#55FBDC" },
				{ cssVar: "--text-5", value: "#4B7FCA" },
				{ cssVar: "--text-6", value: "#00B3D8" },
				{ cssVar: "--text-inverse", value: "#010D20" },
				{ cssVar: "--text-disabled", value: "#4B7FCA" },
			],
		},

		{
			id: "icon",
			name: "icon",
			values: [
				{ cssVar: "--icon-1", value: "#FFFFFF" },
				{ cssVar: "--icon-inverse", value: "#55FBDC" },
				{ cssVar: "--icon-disabled", value: "#4B7FCA" },
			],
		},

		// -------------------------------------------------------------------------
		// BUTTONS
		// -------------------------------------------------------------------------

		{
			id: "backgroundButton",
			name: "backgroundButton",
			values: [
				// 1
				{ cssVar: "--bg-button-1", value: "#FFFFFF" },
				{ cssVar: "--bg-button-1-hover", value: "#E8E7FE" },
				{ cssVar: "--bg-button-1-active", value: "#E8E7FE" },
				{ cssVar: "--bg-button-1-focus", value: "#FFFFFF" },
				{ cssVar: "--bg-button-1-disabled", value: "#6571DF" },
				// 3
				{ cssVar: "--bg-button-3-hover", value: "#FFFFFF33" },
				{ cssVar: "--bg-button-3-active", value: "#E8E7FE" },
				// 4
				{ cssVar: "--bg-link-button-4-hover", value: "#3EDDBF" },
			],
		},

		{
			id: "borderButton",
			name: "borderButton",
			values: [
				// 1
				{ cssVar: "--border-button-1-active", value: "#1B3870" },
				// 3
				{ cssVar: "--border-button-3", value: "#FFFFFF" },
				{ cssVar: "--border-button-3-hover", value: "#FFFFFF" },
				{ cssVar: "--border-button-3-active", value: "#FFFFFF" },
				{ cssVar: "--border-button-3-disabled", value: "#6571DF" },
			],
		},

		{
			id: "textButton",
			name: "textButton",
			values: [
				// 1
				{ cssVar: "--text-button-1", value: "#1510F7" },
				{ cssVar: "--text-button-1-hover", value: "#1510F7" },
				{ cssVar: "--text-button-1-active", value: "#1B3870" },
				{ cssVar: "--text-button-1-focus", value: "#1510F7" },
				{ cssVar: "--text-button-1-disabled", value: "#8DAEDF" },
				// 3
				{ cssVar: "--text-button-3", value: "#FFFFFF" },
				{ cssVar: "--text-button-3-hover", value: "#FFFFFF" },
				{ cssVar: "--text-button-3-active", value: "#1B3870" },
				{ cssVar: "--text-button-3-focus", value: "#FFFFFF" },
				{ cssVar: "--text-button-3-disabled", value: "#8DAEDF" },
				// 4
				{ cssVar: "--text-link-button-4", value: "#3EDDBF" },
				{ cssVar: "--text-link-button-4-hover", value: "#3EDDBF" },
				{ cssVar: "--text-link-button-4-active", value: "#3EDDBF" },
				{ cssVar: "--text-link-button-4-focus", value: "#3EDDBF" },
				{
					cssVar: "--text-link-button-4-disabled",
					value: "#8DAEDF",
				},
			],
		},

		// -------------------------------------------------------------------------
		// LINKS
		// -------------------------------------------------------------------------

		{
			id: "textLink",
			name: "textLink",
			values: [
				// 1
				{ cssVar: "--text-link-1", value: "#82FFF7" },
				{ cssVar: "--text-link-1-hover", value: "#64CCC6" },
				{ cssVar: "--text-link-1-active", value: "#82FFF7" },
				{ cssVar: "--text-link-1-focus", value: "#82FFF7" },
				{ cssVar: "--text-link-1-disabled", value: "#8DAEDF" },
				// Menu
				{ cssVar: "--text-link-menu", value: "#FFFFFF" },
				{ cssVar: "--text-link-menu-hover", value: "#82FFF7" },
				{ cssVar: "--text-link-menu-active", value: "#63E8E0" },
				{ cssVar: "--text-link-menu-focus", value: "#FFFFFF" },
				{ cssVar: "--text-link-menu-disabled", value: "#8DAEDF" },
			],
		},

		// -------------------------------------------------------------------------
		// BORDERS
		// -------------------------------------------------------------------------

		{
			id: "border",
			name: "border",
			values: [
				{ cssVar: "--border-1", value: "#605CFF" },
				{ cssVar: "--border-2", value: "#55FBDC" },
			],
		},

		// -------------------------------------------------------------------------
		// BACKGROUNDS
		// -------------------------------------------------------------------------

		{
			id: "background",
			name: "background",
			values: [
				{ cssVar: "--bg-ui", value: "#1510F7" },
				{ cssVar: "--bg-1", value: "#1510F7" },
				{ cssVar: "--bg-2", value: "#4642FF" },
				{ cssVar: "--bg-3", value: "#6966FA" },
				{ cssVar: "--bg-4", value: "#FFFFFF" },
				{ cssVar: "--bg-disabled", value: "#6571DF" },
			],
		},

		// -------------------------------------------------------------------------
		// OVERLAYS
		// -------------------------------------------------------------------------

		{
			id: "overlay",
			name: "overlay",
			values: [
				{ cssVar: "--overlay-1", value: "#3946FA66" },
				{ cssVar: "--overlay-2", value: "#030F46CC" },
				{ cssVar: "--overlay-3", value: "#000A2580" },
				{ cssVar: "--overlay-4", value: "#000A2566" },
			],
		},
	],
};

export default subtheme;
