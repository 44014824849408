//@ts-check
import { VideoPlaylistProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	themeSelector,
	defaultLink,
	link,
	verticalSpacing,
	loremIpsumParagraph,
	additional,
	subtitle,
	content,
	animation,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<VideoPlaylistProps> = {
	schemaType: "module",
	component: "VideoPlaylist",
	category: "content",
	displayName: "Video Playlist",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					title: "Videos",
					type: "ConditionalField",
					key: "type",
					mandatory: true,
					hideable: false,
					helptext:
						"If you select manually you can add the date and the category",
					options: [
						{
							name: "Video Cards",
							value: "manual",
							title: "Manual",
						},
						{
							name: "Video Playlist",
							value: "playlist",
							title: "Playlist URL",
						},
					],
					fields: [
						{
							condition: "manual",
							title: "Elements",
							type: "ComponentArray",
							key: "elements",
							whiteList: ["VideoPlaylistCard"],
							contentType: "components",
							helptext: "VideoPlaylist Cards",
							maxItems: 15,
						},
						{
							condition: "manual",
							title: "Filters",
							type: "CheckGroup",
							key: "filters",
							options: [
								{ name: "date", title: "Sort by date" },
								{
									name: "category",
									title: "Filter by category",
								},
							],
						},
						{
							condition: "playlist",
							type: "TextField",
							key: "playlist",
							title: "Playlist URL",
							helptext: "YouTube playlist URL",
						},
						{
							condition: "playlist",
							title: "Veil percentaje",
							type: "SliderField",
							key: "playlistVeil",
							step: 10,
							min: 0,
							max: 100,
						},
						{
							condition: "playlist",
							type: "UniqueCheck",
							title: "Show Playlist Thumbnails",
							key: "showPlaylistThumbnails",
							helptext:
								"Retrieves the thumbnails from the playlist (recommended)",
							options: [
								{
									title: "Show Video Thumbnails",
								},
							],
						},
					],
				},
				{
					type: "UniqueCheck",
					title: "Open in modal",
					key: "openInModal",
					helptext: "Open videos in a modal",
					options: [
						{
							title: "Open in modal",
						},
					],
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					helptext:
						"This layout will only be visible on screens larger than 1024px. On smaller screens, Layout01 will be seen by default.",
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/VideoPlaylist/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/VideoPlaylist/Layouts/layout02.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt", "inverse"]),
				},
				{ ...anchorID },
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "VideoPlaylist",
		additional: "Lorem ipsum",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		type: "playlist",
		openInModal: false,
		link: { ...defaultLink, text: "Ver más", variant: "button1" },
		elements: [
			{
				component: "VideoPlaylistCard",
			},
			{
				component: "VideoPlaylistCard",
			},
			{
				component: "VideoPlaylistCard",
			},
		],
		filters: ["date", "category"],
		subtheme: "default",
		animation: "none",
		verticalSpacing: "medium",
		anchorID: null,
		playlistVeil: 50,
		showPlaylistThumbnails: true,
		layout: "layout01",
	},
	thumbnails: {
		"1x": "/thumbnails/modules/VideoPlaylist/thumbnail@1x.png",
		"2x": "/thumbnails/modules/VideoPlaylist/thumbnail@2x.png",
	},
};

export default schema;
