import { LinkedinProps, QuoteProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	heading,
	themeSelector,
	loremIpsumParagraph,
	getThemeOptions,
	getLinkModal,
} from "@schemas/presets";

const schema: Schema.Module<QuoteProps> = {
	schemaType: "module",
	component: "Quote",
	category: "content",
	displayName: "Quote",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					type: "TextField",
					key: "name",
					hideable: true,
				},
				{
					title: "Position",
					type: "TextField",
					key: "position",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					...heading,
					key: "quote",
					title: "Quote",
					isMockup: true,
				},
				{
					key: "decoration",
					title: "Decoration",
					type: "ConditionalField",
					mandatory: true,
					options: [
						{
							name: "image",
							value: "image",
							title: "Image",
						},
						{
							name: "iconQuote",
							value: "iconQuote",
							title: "Icon Quote",
						},
						{
							name: "none",
							value: "none",
							title: "None",
						},
					],
					fields: [
						{
							condition: "image",
							type: "ComponentContainer",
							title: "Image",
							key: "image",
							whiteList: ["Image"],
							mandatory: true,
						},
					],
				},
				{
					type: "ComponentArray",
					title: "Social Media",
					key: "socialMedia",
					contentType: "components",
					whiteList: [
						"Linkedin",
						"Facebook",
						"Twitter",
						"Instagram",
						"Youtube",
						"Other",
						"Mail",
						"Snapchat",
						"TikTok",
						"Flickr",
					],
				},
				{
					title: "Link",
					type: "ComponentContainer",
					whiteList: ["LinkModal"],
					key: "mainLink",
					hideable: true,
					helptext: "Use it to link your page profile or your CV",
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "Quote",
		name: "Name Lastname",
		position: loremIpsumParagraph,
		quote: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		decoration: "image",
		image: { component: "Image" },
		socialMedia: [
			{
				component: "Linkedin",
				value: "",
				icon: "linkedin",
			} as LinkedinProps,
		],
		mainLink: getLinkModal("Ver curriculum", "button4"),
		anchorID: null,
		subtheme: "inverse",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Quote/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Quote/thumbnail@2x.png",
	},
};

export default schema;
