// Gatsby SSR API

const griddoWebfonts = (
	<link
		rel="preload"
		href="/webfonts.css"
		as="style"
		// This id is required in order to usen it later in the `builder.browser.js`
		id="griddo-webfonts"
		crossOrigin
		// This onload only works on Griddo Editor (AX)
		// The code to trigger the onload for the build phase (CX) is th builder.browser.js
		onLoad="this.onload=null;this.rel='stylesheet'"
	/>
);


// onRenderBody hook
function onRenderBody({ setHeadComponents, setBodyAttributes, pathname }) {
	// common headComponents
	const commonHeadComponents = [griddoWebfonts];

	// Set different scripts for Griddo Builder and Griddo Editor.
	const headComponents =
		pathname === "ax-editor"
			? [...commonHeadComponents]
			: [
					...commonHeadComponents,
			  ];

	setHeadComponents(headComponents);
	setBodyAttributes({ id: "griddo-site" });
}

export default {
	onRenderBody,
};
