import { HighlightedContentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	heading,
	themeSelector,
	verticalSpacing,
	content,
	loremIpsumParagraph,
	additional,
	getThemeOptions,
	getLinkModal,
} from "@schemas/presets";

const schema: Schema.Module<HighlightedContentProps> = {
	schemaType: "module",
	component: "HighlightedContent",
	category: "landings",
	displayName: "Highlighted Content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Visual Content",
					key: "visualContent",
					whiteList: ["Image", "Video", "Map"],
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514px",
				},
				{ ...additional },
				{
					...heading,
					mandatory: false,
					hideable: true,
					isMockup: true,
				},
				{
					...content,
				},
				{
					type: "ComponentContainer",
					title: "Main link",
					key: "mainLink",
					whiteList: ["LinkModal"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "HighlightedContent",
		visualContent: {
			image: { component: "Image" },
			video: { component: "Video" },
			map: { component: "Map" },
		},
		additional: "Info",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: loremIpsumParagraph,
		mainLink: getLinkModal("¿Te llamamos?"),
		anchorID: null,
		subtheme: "inverse",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HighlightedContent/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HighlightedContent/thumbnail@2x.png",
	},
};

export default schema;
