import { ARTICLES_EVENTS } from "./ARTICLES_EVENTS";
import { BRAND_SITE } from "./BRAND_SITE";
import { PEOPLE } from "./PEOPLE";
import { STUDIES } from "./STUDIES";

export default {
	ARTICLES_EVENTS,
	BRAND_SITE,
	PEOPLE,
	STUDIES,
};
