import { ModalFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicForm from "@schemas/components/BasicForm";
import {
	heading,
	verticalSpacing,
	themeSelector,
	getThemeOptions,
	loremIpsumParagraph,
	additional,
	content,
} from "@schemas/presets";

const schema: Schema.Module<ModalFormProps> = {
	schemaType: "module",
	component: "ModalForm",
	displayName: "Modal Form",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, isMockup: false },
				{
					...heading,
					mandatory: false,
					isMockup: true,
				},
				{ ...content },
				{
					type: "ComponentContainer",
					title: "Form",
					whiteList: [
						"BasicForm",
						"ContactForm",
						"DownloadForm",
						"EventForm",
						"LandingForm",
					],
					key: "form",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
			],
		},
	],

	default: {
		component: "ModalForm",
		additional: null,
		title: { content: "Lorem Ipsum", tag: "h3" },
		content: loremIpsumParagraph,
		form: {
			basicForm: { ...BasicForm.default },
			contactForm: { component: "ContactForm" },
			downloadForm: { component: "DownloadForm" },
			eventForm: { component: "EventForm" },
			landingForm: { component: "LandingForm" },
		},
		subtheme: "default",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ModalForm/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ModalForm/thumbnail@2x.png",
	},
};

export default schema;
