import * as React from "react";

/**
 * `<Breadcrumb>`
 */
const Breadcrumb = React.lazy(() => import("./Breadcrumb"));

/**
 * `<Button>`
 */
const Button = React.lazy(() => import("./Button"));

/**
 * `<ButtonGroup>`
 */
const ButtonGroup = React.lazy(() => import("./ButtonGroup"));

/**
 * `<Calendar>`
 */
const Calendar = React.lazy(() => import("./Calendar"));

/**
 * `<QuickFacts>`
 */
const QuickFacts = React.lazy(() => import("./QuickFacts"));

/**
 * `<CheckButton>`
 */
const CheckButton = React.lazy(() => import("./Fields/CheckButton"));

/**
 * `<CustomCarousel>`
 */
const CustomCarousel = React.lazy(() => import("./CustomCarousel"));

/**
 * `<Cursor>`
 */
const Cursor = React.lazy(() => import("./Cursor"));

/**
 * `<Dropdown>`
 */
const Dropdown = React.lazy(() => import("./Dropdown"));

/**
 * `<EventCard>`
 */
const EventCard = React.lazy(() => import("./EventCard"));

/**
 * `<EventCardList>`
 */
const EventCardList = React.lazy(() => import("./EventCardList"));

/**
 * `<FileUploader>`
 */
const FileUploader = React.lazy(() => import("./Fields/FileUploader"));

/**
 * `<FormStatusMessage>`
 */
const FormStatusMessage = React.lazy(() => import("./FormStatusMessage"));

/**
 * `<GoToTop>`
 */
const GoToTop = React.lazy(() => import("./GoToTop"));

/**
 * `<Heading>`
 * Renders a html heading tag from `<h1>` to `<h6>`.
 * Supports the same props (attributes) as any heading tag
 * @example
 * <Heading tag="h2">Lorem Ipsum</Heading>
 */
const Heading = React.lazy(() => import("./Heading"));

/**
 * `<HeroNews>`
 */
const HeroNews = React.lazy(() => import("./HeroNews"));

/**
 * `<HeroProgram>`
 */
const HeroProgram = React.lazy(() => import("./HeroProgram"));

/**
 * `<HorizontalNavigation>`
 */
const HorizontalNavigation = React.lazy(() => import("./HorizontalNavigation"));

/**
 * `<Image>`
 */
const Image = React.lazy(() => import("./Image"));

/**
 * `<Icon>`
 * Renders an icon from the library.
 * @example
 * <Icon iconName="arrow" size="xl" svgFill />
 */
const Icon = React.lazy(() => import("./Icon"));

/**
 * `<LoadScriptCode>`
 * Render markdown
 * @example
 * <LoadScriptCode># this is an `<h1>`</LoadScriptCode>
 */
const LoadScriptCode = React.lazy(() => import("./LoadScriptCode"));

/**
 * `<ModuleAnimation>`
 */
const ModuleAnimation = React.lazy(() => import("./ModuleAnimation"));

/**
 * `<ModuleContainer>`
 */
const ModuleContainer = React.lazy(() => import("./ModuleContainer"));

/**
 * `<IntroInstitutional>`
 */
const IntroInstitutional = React.lazy(() => import("./IntroInstitutional"));

/**
 * `<IntroText>`
 */
const IntroText = React.lazy(() => import("./IntroText"));

/**
 * `<ListLoading>`
 */
const ListLoading = React.lazy(() => import("./ListLoading"));

/**
 * `<ListNoResults>`
 */
const ListNoResults = React.lazy(() => import("./ListNoResults"));

/**
 * `<Loading>`
 */
const Loading = React.lazy(() => import("./Loading"));

/**
 * `<NewsCard>`
 */
const NewsCard = React.lazy(() => import("./NewsCard"));

/**
 * `<NavMenu>`
 */
const NavMenu = React.lazy(() => import("./NavMenu"));

/**
 * `<NewsPinnedCard>`
 */
const NewsPinnedCard = React.lazy(() => import("./NewsPinnedCard"));

/**
 * `<NewsRelatedCard>`
 */
const NewsRelatedCard = React.lazy(() => import("./NewsRelatedCard"));

/**
 * `<NumberInput>`
 */
const NumberInput = React.lazy(() => import("./Fields/NumberInput"));

/**
 * `<Pagination>`
 */
const Pagination = React.lazy(() => import("./Pagination"));

/**
 * `<RadioButton>`
 */
const RadioButton = React.lazy(() => import("./Fields/RadioButton"));

/**
 * `<PasswordInput>`
 */
const PasswordInput = React.lazy(() => import("./Fields/PasswordInput"));

/**
 * `<ProgramCard>`
 */
const ProgramCard = React.lazy(() => import("./ProgramCard"));

/**
 * `<ProgramCardList>`
 */
const ProgramCardList = React.lazy(() => import("./ProgramCardList"));

/**
 * `<ScrollToHash>`
 */
const ScrollToHash = React.lazy(() => import("./ScrollToHash"));

/**
 * `<SelectDropdown>`
 */
const SelectDropdown = React.lazy(() => import("./Fields/SelectDropdown"));

/**
 * `<SocialShareLinks>`
 */
const SocialShareLinks = React.lazy(() => import("./SocialShareLinks"));

/**
 * `<SrOnly>`
 * Visually hidden its content.
 * @example
 * <SrOnly>This text will only be read by a screen reader</SrOnly>
 */
const SrOnly = React.lazy(() => import("./SrOnly"));

/**
 * `<StudyPlanContent>`
 */
const StudyPlanContent = React.lazy(() => import("./StudyPlanContent"));

/**
 * `<TestimonialCard>`
 */
const TestimonialCard = React.lazy(() => import("./TestimonialCard"));

/**
 * `<TextArea>`
 */
const TextArea = React.lazy(() => import("./Fields/TextArea"));

/**
 * `<ToggleSwitch>`
 */
const ToggleSwitch = React.lazy(() => import("./Fields/ToggleSwitch"));

/**
 * `<TextButton>`
 */
const TextButton = React.lazy(() => import("./TextButton"));

/**
 * `<TextInput>`
 */
const TextInput = React.lazy(() => import("./Fields/TextInput"));

/**
 * `<Wysiwyg>`
 * Render markdown
 * @example
 * <Wysiwyg># this is an `<h1>`</Wysiwyg>
 */
const Wysiwyg = React.lazy(() => import("./Wysiwyg"));

// Named export
export {
	Breadcrumb,
	Button,
	ButtonGroup,
	Calendar,
	SelectDropdown,
	CheckButton,
	Cursor,
	CustomCarousel,
	Dropdown,
	EventCard,
	EventCardList,
	FileUploader,
	FormStatusMessage,
	GoToTop,
	Heading,
	HeroNews,
	HeroProgram,
	HorizontalNavigation,
	Image,
	Icon,
	LoadScriptCode,
	ModuleAnimation,
	ModuleContainer,
	IntroInstitutional,
	IntroText,
	ListLoading,
	ListNoResults,
	Loading,
	NavMenu,
	NewsCard,
	NewsPinnedCard,
	NewsRelatedCard,
	NumberInput,
	PasswordInput,
	ProgramCard,
	ProgramCardList,
	QuickFacts,
	Pagination,
	RadioButton,
	ScrollToHash,
	SocialShareLinks,
	SrOnly,
	StudyPlanContent,
	TestimonialCard,
	TextArea,
	TextButton,
	TextInput,
	ToggleSwitch,
	Wysiwyg,
};
