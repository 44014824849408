import { Theme } from "@griddo/core";

const primitives: Array<Theme.Primitive> = [
	// -------------------------------------------------------------------------
	// MISC
	// -------------------------------------------------------------------------

	{
		id: "veilColors",
		name: "veilColors",
		values: [
			{ cssVar: "--overlay-0", value: "rgb(24, 24, 24)" },
			{ cssVar: "--overlay-1", value: "rgba(24, 24, 24, 0.2)" },
			{ cssVar: "--overlay-2", value: "rgba(24, 24, 24, 0.4)" },
			{ cssVar: "--overlay-3", value: "rgba(255, 246, 227, 0.2)" },
			{ cssVar: "--overlay-4", value: "rgba(255, 251, 241, 0.4)" },
		],
	},

	{
		id: "shadowColor",
		name: "shadowColor",
		values: [
			{ cssVar: "--shadow-1", value: "14, 47, 132" },
			{ cssVar: "--shadow-2", value: "1, 9, 30" },
		],
	},

	{
		id: "support",
		name: "support",
		values: [
			{ cssVar: "--success", value: "#00610F" },
			{ cssVar: "--error", value: "#FF005C" },
			{ cssVar: "--focus-1", value: "#28FFFF" },
			{ cssVar: "--focus-2", value: "#28FFFF" },
		],
	},

	// -------------------------------------------------------------------------
	// TEXT AND ICONS
	// -------------------------------------------------------------------------

	{
		id: "text",
		name: "text",
		values: [
			{ cssVar: "--text-1", value: "#010E21" },
			{ cssVar: "--text-2", value: "#616780" },
			{ cssVar: "--text-3", value: "#767D87" },
			{ cssVar: "--text-4", value: "#1510F7" },
			{ cssVar: "--text-5", value: "#038DDA" },
			{ cssVar: "--text-6", value: "#0E3E7A" },
			{ cssVar: "--text-7", value: "#45CBB2" },
			{ cssVar: "--text-8", value: "#4EE3C8" },
			{ cssVar: "--text-inverse", value: "#FFFFFF" },
			{ cssVar: "--text-disabled", value: "#B2C3DC" },
		],
	},

	{
		id: "icon",
		name: "icon",
		values: [
			{ cssVar: "--icon-1", value: "#010E21" },
			{ cssVar: "--icon-inverse", value: "#FFFFFF" },
			{ cssVar: "--icon-disabled", value: "#B2C3DC" },
		],
	},

	// -------------------------------------------------------------------------
	// BUTTONS
	// -------------------------------------------------------------------------

	{
		id: "backgroundButton",
		name: "backgroundButton",
		values: [
			// 1
			{ cssVar: "--bg-button-1", value: "#1510F7" },
			{ cssVar: "--bg-button-1-hover", value: "#00A3FF" },
			{ cssVar: "--bg-button-1-active", value: "#038DDA" },
			{ cssVar: "--bg-button-1-focus", value: "#1510F7" },
			{ cssVar: "--bg-button-1-disabled", value: "#DEE8F5" },
			// 3
			{ cssVar: "--bg-button-3", value: "transparent" },
			{ cssVar: "--bg-button-3-hover", value: "#00A3FF26" },
			{ cssVar: "--bg-button-3-active", value: "#00A3FF" },
			{ cssVar: "--bg-button-3-focus", value: "transparent" },
			{ cssVar: "--bg-button-3-disabled", value: "transparent" },
			// 4
			{ cssVar: "--bg-link-button-4", value: "transparent" },
			{ cssVar: "--bg-link-button-4-hover", value: "#1510F7" },
			{ cssVar: "--bg-link-button-4-active", value: "transparent" },
			{ cssVar: "--bg-link-button-4-focus", value: "transparent" },
			{ cssVar: "--bg-link-button-4-disabled", value: "transparent" },
		],
	},

	{
		id: "borderButton",
		name: "borderButton",
		values: [
			// 1
			{ cssVar: "--border-button-1", value: "transparent" },
			{ cssVar: "--border-button-1-hover", value: "transparent" },
			{ cssVar: "--border-button-1-active", value: "transparent" },
			{ cssVar: "--border-button-1-focus", value: "#3EDDBF" },
			{ cssVar: "--border-button-1-disabled", value: "transparent" },
			// 3
			{ cssVar: "--border-button-3", value: "#1510F7" },
			{ cssVar: "--border-button-3-hover", value: "#1510F7" },
			{ cssVar: "--border-button-3-active", value: "#1510F7" },
			{ cssVar: "--border-button-3-focus", value: "#3EDDBF" },
			{ cssVar: "--border-button-3-disabled", value: "#DEE8F5" },
			// 4
			{ cssVar: "--border-button-4", value: "transparent" },
			{ cssVar: "--border-button-4-hover", value: "transparent" },
			{ cssVar: "--border-button-4-active", value: "transparent" },
			{ cssVar: "--border-button-4-focus", value: "#3EDDBF" },
			{ cssVar: "--border-button-4-disabled", value: "transparent" },
		],
	},

	{
		id: "textButton",
		name: "textButton",
		values: [
			// 1
			{ cssVar: "--text-button-1", value: "#FFFFFF" },
			{ cssVar: "--text-button-1-hover", value: "#FFFFFF" },
			{ cssVar: "--text-button-1-active", value: "#FFFFFF" },
			{ cssVar: "--text-button-1-focus", value: "#FFFFFF" },
			{ cssVar: "--text-button-1-disabled", value: "#B2C3DC" },
			// 3
			{ cssVar: "--text-button-3", value: "#1510F7" },
			{ cssVar: "--text-button-3-hover", value: "#1510F7" },
			{ cssVar: "--text-button-3-active", value: "#FFFFFF" },
			{ cssVar: "--text-button-3-focus", value: "#0047FF" },
			{ cssVar: "--text-button-3-disabled", value: "#B2C3DC" },
			// 4
			{ cssVar: "--text-link-button-4", value: "#1510F7" },
			{ cssVar: "--text-link-button-4-hover", value: "#1510F7" },
			{ cssVar: "--text-link-button-4-active", value: "#1510F7" },
			{ cssVar: "--text-link-button-4-focus", value: "#1510F7" },
			{
				cssVar: "--text-link-button-4-disabled",
				value: "#B2C3DC",
			},
		],
	},

	// -------------------------------------------------------------------------
	// LINKS
	// -------------------------------------------------------------------------

	{
		id: "textLink",
		name: "textLink",
		values: [
			// 1
			{ cssVar: "--text-link-1", value: "#1510F7" },
			{ cssVar: "--text-link-1-hover", value: "#00A3FF" },
			{ cssVar: "--text-link-1-active", value: "#038DDA" },
			{ cssVar: "--text-link-1-focus", value: "#1510F7" },
			{ cssVar: "--text-link-1-disabled", value: "#B2C3DC" },
			// Menu
			{ cssVar: "--text-link-menu", value: "#010E21" },
			{ cssVar: "--text-link-menu-hover", value: "#1510F7" },
			{ cssVar: "--text-link-menu-active", value: "#201CD4" },
			{ cssVar: "--text-link-menu-focus", value: "#010E21" },
			{ cssVar: "--text-link-menu-disabled", value: "#B2C3DC" },
		],
	},

	// -------------------------------------------------------------------------
	// BORDERS
	// -------------------------------------------------------------------------

	{
		id: "border",
		name: "border",
		values: [
			{ cssVar: "--border-1", value: "#CFD6E2" },
			{ cssVar: "--border-2", value: "#1510F7" },
		],
	},

	// -------------------------------------------------------------------------
	// BACKGROUNDS
	// -------------------------------------------------------------------------

	{
		id: "background",
		name: "background",
		values: [
			{ cssVar: "--bg-ui", value: "#FFFFFF" },
			{ cssVar: "--bg-1", value: "#FFFFFF" },
			{ cssVar: "--bg-2", value: "#F3F3F7" },
			{ cssVar: "--bg-3", value: "#E6E6EF" },
			{ cssVar: "--bg-4", value: "#010E21" },
			{ cssVar: "--bg-disabled", value: "#D1D7E3" },
		],
	},

	// -------------------------------------------------------------------------
	// OVERLAYS
	// -------------------------------------------------------------------------

	{
		id: "overlay",
		name: "overlay",
		values: [
			{ cssVar: "--overlay-1", value: "#08022C66" },
			{ cssVar: "--overlay-2", value: "#08022CCC" },
			{ cssVar: "--overlay-3", value: "#FFFFFF80" },
			{ cssVar: "--overlay-4", value: "#FFFFFF66" },
		],
	},
];

export default primitives;
