import { ImageGalleryProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	getThemeOptions,
	heading,
	link,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ImageGalleryProps> = {
	schemaType: "module",
	component: "ImageGallery",
	displayName: "Image Gallery",
	category: "collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{
					...heading,
					mandatory: false,
				},
				{ ...subtitle },
				{
					type: "ComponentArray",
					title: "Images",
					key: "images",
					whiteList: ["Image"],
					contentType: "components",
					maxItems: 16,
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt", "inverse"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "ImageGallery",
		additional: null,
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: null,
		images: [
			{ component: "Image" },
			{ component: "Image" },
			{ component: "Image" },
		],
		link: { component: "Link" },
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ImageGallery/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ImageGallery/thumbnail@2x.png",
	},
};

export default schema;
