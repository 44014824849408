import { FeaturedAudiovisualProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	link,
	themeSelector,
	content,
	getThemeOptions,
	loremIpsumParagraph,
	animation,
} from "@schemas/presets";

const schema: Schema.Module<FeaturedAudiovisualProps> = {
	schemaType: "module",
	component: "FeaturedAudiovisual",
	category: "featured",
	displayName: "Featured Audiovisual",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					isMockup: true,
					mandatory: false,
				},

				{
					...content,
				},

				{ ...link, hideable: true },
				{
					title: "Elements",
					type: "ComponentArray",
					key: "elements",
					whiteList: ["Video", "Image"],
					contentType: "components",
					maxItems: 2,
					mandatory: true,
					helptext:
						"Select a maximum of 2 elements. They will appear in your module according to the order of this selection.",
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					...anchorID,
				},

				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							img: "/thumbnails/modules/FeaturedAudiovisual/Layouts/layout01.png",
							value: "layout01",
						},
						{
							img: "/thumbnails/modules/FeaturedAudiovisual/Layouts/layout02.png",
							value: "layout02",
						},
						{
							img: "/thumbnails/modules/FeaturedAudiovisual/Layouts/layout03.png",
							value: "layout03",
						},
						{
							img: "/thumbnails/modules/FeaturedAudiovisual/Layouts/layout04.png",
							value: "layout04",
						},
					],
				},

				{
					...themeSelector,
					columns: 6,
					options: getThemeOptions(["default", "inverse"]),
				},

				{ ...animation },
			],
		},
	],

	default: {
		component: "FeaturedAudiovisual",
		title: { content: "Lorem Ipsum", tag: "h3" },
		content: loremIpsumParagraph,
		link: {
			component: "Link",
		},
		elements: [
			{
				component: "Image",
			},
			{
				component: "Video",
			},
		],
		anchorID: null,
		layout: "layout01",
		subtheme: "default",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedAudiovisual/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FeaturedAudiovisual/thumbnail@2x.png",
	},
};

export default schema;
