import { HorizontalTabsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HorizontalTab from "@schemas/objects/HorizontalTab";
import {
	anchorID,
	heading,
	verticalSpacing,
	themeSelector,
	loremIpsumParagraph,
	additional,
	subtitle,
	content,
	animation,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<HorizontalTabsProps> = {
	schemaType: "module",
	displayName: "Horizontal Tabs",
	category: "interactive",
	component: "HorizontalTabs",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content, hideable: false, isMockup: false },
				{
					title: "Tabs",
					type: "ComponentArray",
					key: "tabs",
					whiteList: ["HorizontalTab"],
					contentType: "components",
					maxItems: 8,
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "HorizontalTabs",
		type: "HorizontalTabs",
		additional: null,
		title: { content: "Lorem Ipsum", tag: "h3" },
		subtitle: loremIpsumParagraph,
		content: undefined,
		tabs: [
			{ ...HorizontalTab.default },
			{ ...HorizontalTab.default },
			{ ...HorizontalTab.default },
		],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HorizontalTabs/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HorizontalTabs/thumbnail@2x.png",
	},
};

export default schema;
