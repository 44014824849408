import { Theme } from "@griddo/core";
import primitives from "@themes/global-theme/colors";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="default-alt"]',
	id: "default-alt",
	name: "The default alternative theme",
	primitives: [
		...primitives,

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#E6F1F8" }],
		},

		// -------------------------------------------------------------------------
		// BACKGROUNDS
		// -------------------------------------------------------------------------

		{
			id: "background",
			name: "background",
			values: [
				{ cssVar: "--bg-ui", value: "#F3F3F7" },
				{ cssVar: "--bg-1", value: "#F3F3F7" },
				{ cssVar: "--bg-2", value: "#FFFFFF" },
				{ cssVar: "--bg-3", value: "#FFFFFF" },
			],
		},
	],
};

export default subtheme;
