import { BRAND_SITE } from "./BRAND_SITE";
import { EVENTS } from "./EVENTS";
import { NEWS } from "./NEWS";
import { PRESS_RELEASES } from "./PRESS_RELEASES";
import { PROGRAMS } from "./PROGRAMS";
import { TESTIMONIAL } from "./TESTIMONIAL";

export default {
	BRAND_SITE,
	EVENTS,
	NEWS,
	PRESS_RELEASES,
	PROGRAMS,
	TESTIMONIAL,
};
