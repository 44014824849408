import { Theme } from "@griddo/core";

const theme: Theme.BaseTheme = {
	selector: '[data-theme="main-theme"]',
	id: "main-theme",
	name: "Main theme",
	primitives: [],
};

export default theme;
