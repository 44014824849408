import { LogoCollectionCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<LogoCollectionCardProps> = {
	schemaType: "component",
	component: "LogoCollectionCard",
	displayName: "Logo Collection Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Logo",
					type: "ConditionalField",
					key: "logoOption",
					hideable: true,
					options: [
						{
							value: "defaultLogo",
							title: "Default",
							name: "defaultLogo",
						},
						{
							value: "customLogo",
							title: "Custom",
							name: "customLogo",
						},
					],
					fields: [
						{
							condition: "defaultLogo",
							title: "Select Logo",
							type: "Select",
							key: "defaultLogo",
							placeholder: "Select a logo",
							mandatory: true,
							options: [
								{ value: "logoMainHorizontalBn", label: "Main" },
								{
									value: "logoMainHorizontal",
									label: "Main color",
								},
								{
									value: "logoSecondaryHorizontal",
									label: "Secondary color",
								},
							],
						},
						{
							condition: "customLogo",
							title: "Image",
							type: "ImageField",
							key: "customLogo",
							mandatory: true,
							helptext: "Size recommendation: 276x90px",
						},
					],
				},
				{
					type: "UrlField",
					title: "URL",
					key: "url",
					placeholder: "https://",
					advanced: true,
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "LogoCollectionCard",
		logoOption: "defaultLogo",
		defaultLogo: "logoMainHorizontalBn",
		url: null,
	},
};

export default schema;
