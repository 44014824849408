import { DownloadableIconCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<DownloadableIconCardProps> = {
	schemaType: "component",
	component: "DownloadableIconCard",
	displayName: "Downloadable Icon Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "title",
					title: "Title",
					mandatory: true,
				},
				{
					title: "Icon type",
					type: "ConditionalField",
					key: "type",
					mandatory: true,
					options: [
						{
							name: "default",
							value: "default",
							title: "Default (project icons)",
						},

						{
							name: "custom",
							value: "custom",
							title: "Custom (upload)",
						},
					],
					fields: [
						{
							condition: "default",
							title: "Select icon",
							type: "Select",
							key: "icon",
							placeholder: "Select an icon",
							options: [
								{ value: "add", label: "Add" },
								{ value: "arrowDown", label: "Arrow down" },
								{ value: "arrowLeft", label: "Arrow left" },
								{ value: "arrowRight", label: "Arrow right" },
								{ value: "arrowUp", label: "Arrow up" },
								{ value: "attachFile", label: "Attach file" },
								{ value: "attachment", label: "Attachment" },
								{ value: "badge", label: "Badge" },
								{ value: "book", label: "Book" },
								{ value: "bookmark", label: "Bookmark" },
								{ value: "calendar", label: "Calendar" },
								{ value: "chat", label: "Chat" },
								{ value: "chat2", label: "Chat 2" },
								{ value: "checkMarkSquare", label: "Checkmark square" },
								{ value: "checkbox", label: "Checkbox" },
								{ value: "checkmark", label: "Checkmark" },
								{ value: "chevronLeft", label: "Chevron left" },
								{ value: "chevronRight", label: "Chevron right" },
								{ value: "clock", label: "Clock" },
								{ value: "close", label: "Close" },
								{ value: "collapse", label: "Collapse" },
								{ value: "computer", label: "Computer" },
								{ value: "copy", label: "Copy" },
								{ value: "creditCard", label: "Credit card" },
								{ value: "dollar", label: "Dollar" },
								{ value: "download", label: "Download" },
								{ value: "dropUp", label: "Drop up" },
								{ value: "dropdown", label: "Drop down" },
								{ value: "duration", label: "Duration" },
								{ value: "email", label: "Email" },
								{ value: "error", label: "Error" },
								{ value: "euro", label: "Euro" },
								{ value: "expand", label: "Expand" },
								{ value: "facebook", label: "Facebook" },
								{ value: "feed", label: "Feed" },
								{ value: "file", label: "File" },
								{ value: "filters", label: "Filters" },
								{ value: "flag", label: "Flag" },
								{ value: "flickr", label: "Flickr" },
								{ value: "folder", label: "Folder" },
								{ value: "globe", label: "Globe" },
								{ value: "google", label: "Google" },
								{ value: "graduationCap", label: "Graduation cap" },
								{ value: "hide", label: "Hide" },
								{ value: "image", label: "Image" },
								{ value: "info", label: "Info" },
								{ value: "instagram", label: "Instagram" },
								{ value: "label", label: "Label" },
								{ value: "linkedin", label: "LinkedIn" },
								{ value: "list", label: "List" },
								{ value: "location", label: "Location" },
								{ value: "lock", label: "Lock" },
								{ value: "menu", label: "Menu" },
								{ value: "mic", label: "Mic" },
								{ value: "minus", label: "Minus" },
								{ value: "moreH", label: "More H" },
								{ value: "moreV", label: "More V" },
								{ value: "other", label: "Other" },
								{ value: "paperPlane", label: "PaperPlane" },
								{ value: "phone", label: "Phone" },
								{ value: "pdf", label: "PDF" },
								{ value: "percent", label: "Percent" },
								{ value: "play", label: "Play" },
								{ value: "priority", label: "Priority" },
								{ value: "question", label: "Question" },
								{ value: "quoteIcon", label: "Quote icon" },
								{ value: "quoteSelf", label: "Quote self" },
								{ value: "radioButtonChecked", label: "Radio button checked" },
								{ value: "radio", label: "Radio" },
								{ value: "remove", label: "Remove" },
								{ value: "resizerHandle", label: "Resizer handle" },
								{ value: "save", label: "Save" },
								{ value: "search", label: "Search" },
								{ value: "share", label: "Share" },
								{ value: "shield", label: "Shield" },
								{ value: "show", label: "Show" },
								{ value: "snapchat", label: "Snapchat" },
								{ value: "sort", label: "Sort" },
								{ value: "spotify", label: "Spotify" },
								{ value: "stage", label: "Stage" },
								{ value: "swap", label: "Swap" },
								{ value: "tiktok", label: "TikTok" },
								{ value: "triangleDown", label: "Triangle down" },
								{ value: "triangleUp", label: "Triangle up" },
								{ value: "twitter", label: "Twitter" },
								{ value: "unlock", label: "Unlock" },
								{ value: "upload", label: "Upload" },
								{ value: "user", label: "User" },
								{ value: "user2", label: "User 2" },
								{ value: "userLocation", label: "User location" },
								{ value: "verifiedShield", label: "VerifiedShield" },
								{ value: "warning", label: "Warning" },
								{ value: "whatsappNoBg", label: "WhatsApp no bg" },
								{ value: "youtube", label: "YouYube" },
							],
						},
						{
							condition: "custom",
							title: ".PNG or .JPG Format",
							type: "ImageField",
							key: "iconImage",
							hideable: true,
							helptext:
								"Recommended use a vector or minimum image size: 120x120",
						},
						{
							condition: "custom",
							title: ".SVG Format",
							type: "ImageField",
							key: "iconSvg",
							hideable: true,
							helptext:
								"Recommended use a vector or minimum image size: 120x120",
						},
					],
				},
			],
		},
	],

	default: {
		component: "DownloadableIconCard",
		title: "Lorem ipsum",
		type: "default",
	},
};

export default schema;
