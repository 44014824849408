import { LinkProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { defaultLink } from "@schemas/presets";

const schema: Schema.Component<LinkProps> = {
	schemaType: "component",
	displayName: "Link",
	component: "Link",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					_autoType: {
						doc: "/** Url field type */",
					},
					type: "UrlField",
					title: "Url",
					key: "url",
					advanced: true,
				},
				{
					_autoType: {
						doc: "/** Text for the button */",
					},
					type: "TextField",
					title: "Text",
					key: "text",
					placeholder: "Link text",
					humanReadable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					_autoType: {
						doc: "/** Button variant style */",
					},
					title: "Variant",
					key: "variant",
					type: "VisualUniqueSelection",
					columns: 3,
					options: [
						{
							value: "button1",
							img: "/thumbnails/components/Link/button1@2x.png",
						},
						{
							value: "button3",
							img: "/thumbnails/components/Link/button3@2x.png",
						},
						{
							value: "button4",
							img: "/thumbnails/components/Link/button4@2x.png",
						},
					],
				},
			],
		},
	],

	default: {
		...defaultLink,
		text: "",
		variant: "button1",
	},
};

export default schema;
