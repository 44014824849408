import { LandingQuickFactsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	getThemeOptions,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<LandingQuickFactsProps> = {
	schemaType: "module",
	component: "LandingQuickFacts",
	displayName: "Quick Facts",
	category: "landings",

	configTabs: [
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "inverse", "accent"]),
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "LandingQuickFacts",
		anchorID: null,
		subtheme: "inverse",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/LandingQuickFacts/thumbnail@1x.png",
		"2x": "/thumbnails/modules/LandingQuickFacts/thumbnail@2x.png",
	},
};

export default schema;
