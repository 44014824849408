import { PressReleasesDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import ContactInfo from "@schemas/modules/ContactInfo";

const schema: Schema.Template<PressReleasesDetailProps> = {
	schemaType: "template",
	component: "PressReleasesDetail",
	displayName: "Press Releases",
	dataPacks: ["PRESS_RELEASES"],
	type: {
		label: "Press Releases",
		value: "Press Releases",
		mode: "detail",
	},

	content: [
		{
			type: "DateField",
			title: "Date",
			key: "itemDate",
			hideable: true,
		},
		{
			title: "Logo",
			type: "ConditionalField",
			key: "logoOption",
			hideable: true,
			options: [
				{
					value: "defaultLogo",
					title: "Default",
					name: "defaultLogo",
				},
				{
					value: "customLogo",
					title: "Custom",
					name: "customLogo",
				},
			],
			fields: [
				{
					condition: "defaultLogo",
					title: "Select Logo",
					type: "Select",
					key: "defaultLogo",
					placeholder: "Select a logo",
					mandatory: true,
					options: [
						{ value: "logoMainHorizontalBn", label: "Main" },
						{
							value: "logoMainHorizontal",
							label: "Main color",
						},
						{
							value: "logoMainHorizontalInverse",
							label: "Main inverse",
						},
						{
							value: "logoSecondaryHorizontal",
							label: "Secondary color",
						},
					],
				},
				{
					condition: "customLogo",
					title: "Image",
					type: "ImageField",
					key: "customLogo",
					mandatory: true,
					helptext: "Size recommendation: 276x90px",
				},
			],
		},
		{
			type: "Wysiwyg",
			title: "Content",
			key: "content",
			hideable: true,
			placeholder: "Type a press release content",
			humanReadable: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"ContactInfo",
				"IntroForm",
				"VideoPlaylist",
			],
			contentType: "modules",
		},
	],

	config: [
		{
			type: "RadioGroup",
			key: "titleSize",
			title: "Title Size",
			mandatory: true,
			options: [
				{ name: "m", value: "m", title: "m" },
				{ name: "l", value: "l", title: "l" },
			],
		},
	],

	default: {
		type: "template",
		templateType: "PressReleasesDetail",
		logoOption: null,
		defaultLogo: "logoMainHorizontalBn",
		customLogo: undefined,
		titleSize: "l",
		content: null,
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [{ ...ContactInfo.default, subtheme: "default-alt" }],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/PressReleasesDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/PressReleasesDetail/thumbnail@2x.png",
	},
};

export default schema;
