import { ACADEMIC_AREA } from "./ACADEMIC_AREA";
import { CENTER } from "./CENTER";
import { DOCUMENT } from "./DOCUMENT";
import { ENTRY_DATES } from "./ENTRY_DATES";
import { LANGUAGE } from "./LANGUAGE";
import { PEOPLE } from "./PEOPLE";
import { PROGRAM_TYPE } from "./PROGRAM_TYPE";
import { SCHOOL } from "./SCHOOL";
import { STUDY_PLAN } from "./STUDY_PLAN";
import { TESTIMONIAL } from "./TESTIMONIAL";

export default {
	ACADEMIC_AREA,
	CENTER,
	DOCUMENT,
	ENTRY_DATES,
	LANGUAGE,
	PEOPLE,
	PROGRAM_TYPE,
	SCHOOL,
	STUDY_PLAN,
	TESTIMONIAL,
};
