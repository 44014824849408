import { HeroSideProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicForm from "@schemas/components/BasicForm";
import {
	heading,
	anchorID,
	themeSelector,
	loremIpsumParagraph,
	animation,
	content,
	defaultLink,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<HeroSideProps> = {
	schemaType: "module",
	component: "HeroSide",
	category: "heros",
	displayName: "Hero Side",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					hideable: false,
					isMockup: true,
					mandatory: true,
				},
				{
					...content,
				},
				{
					title: "Quick Facts",
					type: "FieldGroup",
					key: "quickFacts",
					fields: [
						{
							type: "DateField",
							title: "Date (Starts On)",
							key: "intake",
							hideable: true,
						},
						{
							type: "MultiCheckSelect",
							title: "Centers",
							placeholder: "Centers",
							key: "centers",
							source: "CENTER",
							hideable: true,
						},
						{
							type: "MultiCheckSelect",
							title: "Languages",
							placeholder: "Languages",
							key: "languages",
							source: "LANGUAGE",
							hideable: true,
						},
						{
							type: "MultiCheckSelect",
							title: "Locations",
							placeholder: "Locations",
							key: "locations",
							source: "LOCATION",
							hideable: true,
						},
					],
				},
				{
					title: "Custom Quick Facts",
					type: "ComponentArray",
					key: "customQuickFacts",
					whiteList: ["QuickFact"],
					contentType: "components",
				},
				{
					title: "Side content",
					type: "ConditionalField",
					key: "sideContent",
					mandatory: true,
					options: [
						{
							name: "form",
							value: "form",
							title: "Form",
						},

						{
							name: "image",
							value: "image",
							title: "Image",
						},
					],

					fields: [
						{
							condition: "form",
							type: "ComponentContainer",
							title: "Form",
							key: "form",
							whiteList: [
								"BasicForm",
								"ContactForm",
								"DownloadForm",
								"EventForm",
							],
						},
						{
							condition: "image",
							type: "ComponentContainer",
							title: "Image",
							key: "image",
							whiteList: ["Image"],
						},
					],
				},
				{
					type: "ComponentContainer",
					title: "Link 1",
					key: "link1",
					whiteList: ["Link"],
					hideable: true,
				},
				{
					type: "ComponentContainer",
					title: "Link 2",
					key: "link2",
					whiteList: ["Link"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{
					type: "RadioGroup",
					key: "titleSize",
					title: "Title Size",
					mandatory: true,
					options: [
						{ name: "s", value: "s", title: "s" },
						{ name: "m", value: "m", title: "m" },
						{ name: "l", value: "l", title: "l" },
						{ name: "xl", value: "xl", title: "xl" },
					],
				},
				{ ...animation },
				{
					type: "UniqueCheck",
					key: "breadcrumbsDesktop",
					title: "Breadcrumbs Desktop/Tablet",
					options: [{ title: "Display in tablet and desktop" }],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbs",
					title: "Breadcrumbs Mobile",
					options: [{ title: "Display in mobile" }],
				},
			],
		},
	],

	thumbnails: {
		"1x": "/thumbnails/modules/HeroSide/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroSide/thumbnail@2x.png",
	},

	default: {
		component: "HeroSide",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		content: loremIpsumParagraph,
		sideContent: "form",
		form: {
			basicForm: { ...BasicForm.default },
			contactForm: { component: "ContactForm" },
			downloadForm: { component: "DownloadForm" },
			eventForm: { component: "EventForm" },
		},
		image: { component: "Image" },
		legalConditionLink: undefined,
		customQuickFacts: [],
		link1: {
			...defaultLink,
			variant: "button3",
			text: "Ver más",
		},
		link2: {
			...defaultLink,
			variant: "button4",
			text: "Ver más",
		},
		anchorID: null,
		subtheme: "default",
		titleSize: "l",
		animation: "fadeIn",
		breadcrumbsDesktop: true,
		breadcrumbs: true,
	},
};

export default schema;
