import AccordionCollection from "./AccordionCollection";
import AnimatedImageGallery from "./AnimatedImageGallery";
import BasicContent from "./BasicContent";
import BasicHeroBrandSite from "./BasicHeroBrandSite";
import BasicText from "./BasicText";
import CardCollection from "./CardCollection";
import CardsCarousel from "./CardsCarousel";
import ChartCollection from "./ChartCollection";
import ColorCollection from "./ColorCollection";
import ContactInfo from "./ContactInfo";
import Countdown from "./Countdown";
import CypherCollection from "./CypherCollection";
import DownloadableIconCollection from "./DownloadableIconCollection";
import DownloadableLogoCollection from "./DownloadableLogoCollection";
import DownloadCollection from "./DownloadCollection";
import EventsDistributor from "./EventsDistributor";
import FAQCollection from "./FAQCollection";
import FeaturedAudiovisual from "./FeaturedAudiovisual";
import FeaturedBlock from "./FeaturedBlock";
import FeaturedContent from "./FeaturedContent";
import FeaturedFacts from "./FeaturedFacts";
import Footer from "./Footer";
import FooterLanding from "./FooterLanding";
import FullVideo from "./FullVideo";
import Header from "./Header";
import HeroCarousel from "./HeroCarousel";
import HeroInner from "./HeroInner";
import HeroLanding from "./HeroLanding";
import HeroSide from "./HeroSide";
import HeroVideo from "./HeroVideo";
import HighlightedCardCollection from "./HighlightedCardCollection";
import HighlightedContent from "./HighlightedContent";
import HighlightedHeader from "./HighlightedHeader";
import HorizontalTabs from "./HorizontalTabs";
import ImageBanner from "./ImageBanner";
import ImageGallery from "./ImageGallery";
import InnerBasicContent from "./InnerBasicContent";
import InnerBasicText from "./InnerBasicText";
import InnerCardCollection from "./InnerCardCollection";
import InnerFeaturedAudiovisual from "./InnerFeaturedAudiovisual";
import InnerFullVideo from "./InnerFullVideo";
import InnerPeopleDistributor from "./InnerPeopleDistributor";
import InnerProgramsDistributor from "./InnerProgramsDistributor";
import InnerStepCollection from "./InnerStepCollection";
import InnerVerticalTabs from "./InnerVerticalTabs";
import InnerWidget from "./InnerWidget";
import IntroForm from "./IntroForm";
import LandingHeader from "./LandingHeader";
import LandingProgramsDistributor from "./LandingProgramsDistributor";
import LandingQuickFacts from "./LandingQuickFacts";
import LogoCollection from "./LogoCollection";
import LogoThemer from "./LogoThemer";
import MainHero from "./MainHero";
import Milestones from "./Milestones";
import ModalForm from "./ModalForm";
import NewsDistributor from "./NewsDistributor";
import NewsPinnedDistributor from "./NewsPinnedDistributor";
import PeopleDistributor from "./PeopleDistributor";
import ProgramIntroForm from "./ProgramIntroForm";
import ProgramNavigation from "./ProgramNavigation";
import ProgramsDistributor from "./ProgramsDistributor";
import Quote from "./Quote";
import SocialShare from "./SocialShare";
import Spacer from "./Spacer";
import StepCollection from "./StepCollection";
import StickyTabs from "./StickyTabs";
import StudyPlan from "./StudyPlan";
import Table from "./Table";
import TestimonialDistributor from "./TestimonialDistributor";
import TextCarousel from "./TextCarousel";
import TypographyPreviewer from "./TypographyPreviewer";
import VerticalTabs from "./VerticalTabs";
import VideoPlaylist from "./VideoPlaylist";
import Widget from "./Widget";
import Wysiwyg from "./Wysiwyg";

export default {
	AccordionCollection,
	AnimatedImageGallery,
	BasicContent,
	BasicHeroBrandSite,
	BasicText,
	CardsCarousel,
	ColorCollection,
	CardCollection,
	ChartCollection,
	ContactInfo,
	Countdown,
	CypherCollection,
	DownloadableIconCollection,
	DownloadableLogoCollection,
	DownloadCollection,
	EventsDistributor,
	FAQCollection,
	FeaturedAudiovisual,
	FeaturedBlock,
	FeaturedContent,
	ImageBanner,
	ImageGallery,
	InnerBasicText,
	InnerBasicContent,
	InnerCardCollection,
	InnerWidget,
	InnerProgramsDistributor,
	InnerStepCollection,
	InnerVerticalTabs,
	InnerFeaturedAudiovisual,
	InnerFullVideo,
	InnerPeopleDistributor,
	IntroForm,
	FeaturedFacts,
	Footer,
	FooterLanding,
	FullVideo,
	Header,
	HeroCarousel,
	HeroInner,
	HeroLanding,
	HeroSide,
	HighlightedContent,
	HighlightedHeader,
	HeroVideo,
	HighlightedCardCollection,
	HorizontalTabs,
	PeopleDistributor,
	LandingHeader,
	LandingProgramsDistributor,
	LandingQuickFacts,
	LogoCollection,
	LogoThemer,
	MainHero,
	Milestones,
	ModalForm,
	NewsDistributor,
	NewsPinnedDistributor,
	ProgramNavigation,
	ProgramIntroForm,
	ProgramsDistributor,
	Quote,
	SocialShare,
	Spacer,
	StepCollection,
	StickyTabs,
	StudyPlan,
	Table,
	TestimonialDistributor,
	TextCarousel,
	TypographyPreviewer,
	VerticalTabs,
	VideoPlaylist,
	Widget,
	Wysiwyg,
};
