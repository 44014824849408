import { Theme } from "@griddo/core";
import primitives from "@themes/global-theme/colors";

const subtheme: Theme.BaseTheme = {
	selector: '[data-subtheme="inverse"]',
	id: "inverse",
	name: "The inverse theme",
	primitives: [
		...primitives,

		// -------------------------------------------------------------------------
		// MISC
		// -------------------------------------------------------------------------

		{
			id: "brandColors",
			name: "brandColors",
			values: [{ cssVar: "--brand-1", value: "#1510F7" }],
		},

		{
			id: "support",
			name: "support",
			values: [{ cssVar: "--success", value: "#8ECC0B" }],
		},

		// -------------------------------------------------------------------------
		// TEXT AND ICONS
		// -------------------------------------------------------------------------

		{
			id: "text",
			name: "text",
			values: [
				{ cssVar: "--text-1", value: "#FFFFFF" },
				{ cssVar: "--text-2", value: "#BCC1D4" },
				{ cssVar: "--text-3", value: "#8A91AB" },
				{ cssVar: "--text-5", value: "#00A3FF" },
				{ cssVar: "--text-6", value: "#00B3D8" },
				{ cssVar: "--text-inverse", value: "#010D20" },
				{ cssVar: "--text-disabled", value: "#5377AB" },
			],
		},

		{
			id: "icon",
			name: "icon",
			values: [
				{ cssVar: "--icon-1", value: "#FFFFFF" },
				{ cssVar: "--icon-inverse", value: "#010D20" },
				{ cssVar: "--icon-disabled", value: "#5377AB" },
			],
		},

		// -------------------------------------------------------------------------
		// BUTTONS
		// -------------------------------------------------------------------------

		{
			id: "backgroundButton",
			name: "backgroundButton",
			values: [
				// 1
				{ cssVar: "--bg-button-1", value: "#FFFFFF" },
				{ cssVar: "--bg-button-1-hover", value: "#DFDFFF" },
				{ cssVar: "--bg-button-1-active", value: "#FFFFFF" },
				{ cssVar: "--bg-button-1-focus", value: "#FFFFFF" },
				{ cssVar: "--bg-button-1-disabled", value: "#162740" },
				// 3
				{ cssVar: "--bg-button-3-hover", value: "#DFDFFF33" },
				{ cssVar: "--bg-button-3-active", value: "#DFDFFF" },
				// 4
				{ cssVar: "--bg-link-button-4-hover", value: "#3EDDBF" },
			],
		},

		{
			id: "borderButton",
			name: "borderButton",
			values: [
				// 3
				{ cssVar: "--border-button-3", value: "#FFFFFF" },
				{ cssVar: "--border-button-3-hover", value: "#FFFFFF" },
				{ cssVar: "--border-button-3-active", value: "#FFFFFF" },
				{ cssVar: "--border-button-3-disabled", value: "#162740" },
			],
		},

		{
			id: "textButton",
			name: "textButton",
			values: [
				// 1
				{ cssVar: "--text-button-1", value: "#1510F7" },
				{ cssVar: "--text-button-1-hover", value: "#1510F7" },
				{ cssVar: "--text-button-1-active", value: "#1510F7" },
				{ cssVar: "--text-button-1-focus", value: "#1510F7" },
				{ cssVar: "--text-button-1-disabled", value: "#7A8FAE" },
				// 3
				{ cssVar: "--text-button-3", value: "#FFFFFF" },
				{ cssVar: "--text-button-3-hover", value: "#FFFFFF" },
				{ cssVar: "--text-button-3-active", value: "#1510F7" },
				{ cssVar: "--text-button-3-focus", value: "#FFFFFF" },
				{ cssVar: "--text-button-3-disabled", value: "#7A8FAE" },
				// 4
				{ cssVar: "--text-link-button-4", value: "#3EDDBF" },
				{ cssVar: "--text-link-button-4-hover", value: "#3EDDBF" },
				{ cssVar: "--text-link-button-4-active", value: "#3EDDBF" },
				{ cssVar: "--text-link-button-4-focus", value: "#3EDDBF" },
				{
					cssVar: "--text-link-button-4-disabled",
					value: "#7A8FAE",
				},
			],
		},

		// -------------------------------------------------------------------------
		// LINKS
		// -------------------------------------------------------------------------

		{
			id: "textLink",
			name: "textLink",
			values: [
				// 1
				{ cssVar: "--text-link-1", value: "#82FFF7" },
				{ cssVar: "--text-link-1-hover", value: "#64CCC6" },
				{ cssVar: "--text-link-1-active", value: "#82FFF7" },
				{ cssVar: "--text-link-1-focus", value: "#82FFF7" },
				{ cssVar: "--text-link-1-disabled", value: "#7A8FAE" },
				// Menu
				{ cssVar: "--text-link-menu", value: "#FFFFFF" },
				{ cssVar: "--text-link-menu-hover", value: "#00B3D8" },
				{ cssVar: "--text-link-menu-active", value: "#0897B4" },
				{ cssVar: "--text-link-menu-focus", value: "#FFFFFF" },
				{ cssVar: "--text-link-menu-disabled", value: "#7A8FAE" },
			],
		},

		// -------------------------------------------------------------------------
		// BORDERS
		// -------------------------------------------------------------------------

		{
			id: "border",
			name: "border",
			values: [
				{ cssVar: "--border-1", value: "#626E84" },
				{ cssVar: "--border-2", value: "#CFD6E2" },
			],
		},

		// -------------------------------------------------------------------------
		// BACKGROUNDS
		// -------------------------------------------------------------------------

		{
			id: "background",
			name: "background",
			values: [
				{ cssVar: "--bg-ui", value: "#010D20" },
				{ cssVar: "--bg-1", value: "#010D20" },
				{ cssVar: "--bg-2", value: "#0C192E" },
				{ cssVar: "--bg-3", value: "#1C2D46" },
				{ cssVar: "--bg-4", value: "#FFFFFF" },
				{ cssVar: "--bg-disabled", value: "#5377AB" },
			],
		},

		// -------------------------------------------------------------------------
		// OVERLAYS
		// -------------------------------------------------------------------------

		{
			id: "overlay",
			name: "overlay",
			values: [
				{ cssVar: "--overlay-1", value: "#3F3C5366" },
				{ cssVar: "--overlay-2", value: "#D9DDE9CC" },
				{ cssVar: "--overlay-3", value: "#000A2599" },
				{ cssVar: "--overlay-4", value: "#000A2566" },
			],
		},
	],
};

export default subtheme;
