import { LogoThemerProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import LogoThemeCard from "@schemas/components/LogoThemeCard";
import {
	additional,
	anchorID,
	animation,
	content,
	subtitle,
	heading,
	loremIpsumParagraph,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<LogoThemerProps> = {
	schemaType: "module",
	component: "LogoThemer",
	category: "collection",
	displayName: "Logo Themer",
	dataPacks: ["BRAND_SITE"],
	sectionList: { BasicTemplate: ["mainSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ConditionalField",
					title: "Intro Type",
					key: "introType",
					mandatory: true,
					helptext: "Select institutional intro if you want to place a logo",
					options: [
						{
							name: "defaultIntro",
							value: "defaultIntro",
							title: "Default Intro",
						},
						{
							name: "institutionalIntro",
							value: "institutionalIntro",
							title: "Institutional Intro",
						},
					],
					fields: [
						{
							condition: "defaultIntro",
							...additional,
						},
						{
							condition: "defaultIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},
						{
							condition: "defaultIntro",
							...subtitle,
						},
						{
							condition: "defaultIntro",
							...content,
						},

						{
							condition: "institutionalIntro",
							title: "Select Logo",
							type: "Select",
							key: "logo",
							placeholder: "Select a logo",
							mandatory: true,
							options: [
								{ value: "logoMainHorizontalBn", label: "Main" },
								{
									value: "logoMainHorizontal",
									label: "Main color",
								},
								{
									value: "logoMainHorizontalInverse",
									label: "Main inverse",
								},
								{
									value: "logoSecondaryHorizontal",
									label: "Secondary color",
								},
							],
						},
						{
							condition: "institutionalIntro",
							...additional,
						},
						{
							condition: "institutionalIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},
						{
							condition: "institutionalIntro",
							...content,
						},
					],
				},
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["LogoThemeCard"],
					contentType: "components",
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchorID }, { ...verticalSpacing }, { ...animation }],
		},
	],

	default: {
		component: "LogoThemer",
		introType: "defaultIntro",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: null,
		logo: "logoMainHorizontalBn",
		elements: [
			{ ...LogoThemeCard.default },
			{ ...LogoThemeCard.default },
			{ ...LogoThemeCard.default },
		],
		anchorID: null,
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/LogoThemer/thumbnail@1x.png",
		"2x": "/thumbnails/modules/LogoThemer/thumbnail@2x.png",
	},
};

export default schema;
