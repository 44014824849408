import { Schema } from "@griddo/core";

export const ACADEMIC_AREA: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS", "TESTIMONIAL"],
	title: "Academic Area",
	local: true,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
			},
		],
	},
};
