import { EventDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import FAQCollection from "@schemas/modules/FAQCollection";
import PeopleDistributor from "@schemas/modules/FAQCollection";
import { heading, link, loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Template<EventDetailProps> = {
	schemaType: "template",
	component: "EventDetail",
	displayName: "Event",
	dataPacks: ["EVENTS"],

	type: {
		label: "Event",
		value: "event",
		mode: "detail",
	},

	content: [
		{
			type: "DateField",
			title: "Date",
			key: "eventDate",
			mandatory: true,
		},
		{
			type: "TimeField",
			title: "Time",
			key: "eventTime",
			mandatory: true,
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "Abstract",
			key: "abstract",
			hideable: true,
			isMockup: true,
			placeholder: "Type an abstract text",
			humanReadable: true,
		},
		{
			type: "MultiCheckSelectGroup",
			title: "Select Categories",
			key: "categories",
			filled: true,
			elements: [
				{
					placeholder: "Centers",
					key: "centers",
					source: "CENTER",
				},
				{
					placeholder: "Schools",
					key: "schools",
					source: "SCHOOL",
				},
				{
					placeholder: "Services",
					key: "services",
					source: "SERVICE",
				},
				{
					placeholder: "Locations",
					key: "locations",
					source: "LOCATION",
				},
				{
					placeholder: "Topics",
					key: "topics",
					source: "TOPIC",
				},
				{
					placeholder: "Event type",
					key: "type",
					source: "EVENT_TYPE",
				},
			],
		},
		{
			...heading,
			key: "introTitle",
			title: "Intro title",
			mandatory: false,
		},
		{
			type: "Wysiwyg",
			full: false,
			key: "introSubtitle",
			title: "Intro subtitle",
			hideable: true,
			humanReadable: true,
		},
		{
			type: "ConditionalField",
			key: "registrations",
			title: "Event registrations",
			options: [
				{
					title: "Open",
					name: "open",
					value: "open",
				},
				{
					title: "Closed",
					name: "closed",
					value: "closed",
				},
			],
			fields: [
				{
					type: "ComponentContainer",
					title: "Form",
					key: "form",
					whiteList: ["EventForm"],
					hideable: true,
					condition: "open",
				},
				{
					type: "TextField",
					title: "Closed reg. title",
					key: "closedTitle",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
					condition: "closed",
				},
				{
					type: "TextField",
					title: "Closed reg. content",
					key: "closedContent",
					hideable: true,
					humanReadable: true,
					condition: "closed",
				},
				{
					type: "ComponentContainer",
					title: "Closed reg. link",
					key: "closedLink",
					whiteList: ["SimpleLink"],
					hideable: true,
					condition: "closed",
				},
			],
		},
		{
			type: "ComponentContainer",
			title: "Image",
			key: "image",
			whiteList: ["Image"],
		},
		{
			title: "Custom Quick Facts",
			type: "ComponentArray",
			key: "customQuickFacts",
			whiteList: ["QuickFact"],
			contentType: "modules",
		},
		{
			type: "ImageField",
			title: "Image",
			key: "thumbImage",
			helptext: "Show a thumbnail in distributors and lists",
			mandatory: true,
		},
		{ ...link, title: "Button 1" },
		{ ...link, key: "link2", title: "Button 2" },
		{
			type: "RadioGroup",
			key: "showCategoryChips",
			title: "Show category chips",
			options: [
				{ name: "yes", title: "Yes", value: true },
				{ name: "no", title: "No", value: false },
			],
			mandatory: true,
		},
		{
			title: "Social Media",
			type: "CheckGroup",
			key: "socialMedia",
			options: [
				{ name: "facebook", title: "Facebook" },
				{ name: "linkedin", title: "LinkedIn" },
				{ name: "twitter", title: "X (Twitter)" },
				{ name: "paperPlane", title: "Email" },
			],
			hideable: true,
		},
		{
			type: "ComponentArray",
			title: "Main Content",
			key: "mainContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"ChartCollection",
				"ContactInfo",
				"FAQCollection",
				"IntroForm",
				"VideoPlaylist",
			],
			contentType: "modules",
		},
		{
			type: "UniqueCheck",
			key: "breadcrumbsDesktop",
			title: "Breadcrumbs Desktop/Tablet",
			options: [{ title: "Display in tablet and desktop" }],
		},
		{
			type: "UniqueCheck",
			key: "breadcrumbs",
			title: "Breadcrumbs Mobile",
			options: [{ title: "Display in mobile" }],
		},
	],

	default: {
		type: "template",
		templateType: "EventDetail",
		abstract: loremIpsumParagraph,
		introTitle: null,
		introSubtitle: null,
		registrations: "open",
		closedLink: { component: "SimpleLink" },
		form: { component: "EventForm" },
		closedTitle: "Lorem ipsum",
		image: { component: "Image" },
		customQuickFacts: {
			component: "Section",
			name: "Custom Quick Facts",
			modules: [],
		},
		link: { component: "Link" },
		link2: { component: "Link" },
		showCategoryChips: false,
		socialMedia: ["linkedin"],
		mainContent: {
			component: "Section",
			name: "Main Content",
			modules: [{ ...PeopleDistributor.default }, { ...FAQCollection.default }],
		},
		breadcrumbsDesktop: false,
		breadcrumbs: false,
	},

	thumbnails: {
		"1x": "/thumbnails/templates/EventDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/EventDetail/thumbnail@2x.png",
	},
};

export default schema;
