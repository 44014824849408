import { ImageBannerProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	content,
	getLinkModal,
	getThemeOptions,
	heading,
	linkModal,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ImageBannerProps> = {
	schemaType: "module",
	component: "ImageBanner",
	displayName: "Image banner",
	category: "content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Background image",
					key: "background",
					whiteList: ["BackgroundImage"],
					helptext: "Recommended minimum image size: 792x514",
					mandatory: true,
				},
				{
					title: "Open image in ligthbox",
					key: "openLightbox",
					type: "RadioGroup",
					options: [
						{ name: "yes", value: "yes", title: "Yes" },
						{ name: "no", value: "no", title: "No" },
					],
				},
				{ ...heading, isMockup: true },
				{ ...subtitle, hideable: false, mandatory: true },
				{ ...content },
				{ ...linkModal, mandatory: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "RadioGroup",
					title: "Full height",
					key: "fullHeight",
					mandatory: true,
					options: [
						{
							value: false,
							name: "no",
							title: "No",
						},
						{
							value: true,
							name: "yes",
							title: "Yes",
						},
					],
				},
				{
					type: "RadioGroup",
					title: "Content alignment",
					key: "alignment",
					mandatory: true,
					options: [
						{
							value: "left",
							name: "left",
							title: "Left",
						},
						{
							value: "center",
							name: "center",
							title: "Center",
						},
						{
							value: "right",
							name: "right",
							title: "Right",
						},
					],
				},
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/ImageBanner/Layouts/layout001.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/ImageBanner/Layouts/layout002.png",
						},
					],
				},
				{ ...themeSelector, options: getThemeOptions(["default", "inverse"]) },
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "ImageBanner",
		background: { component: "BackgroundImage" },
		openLightbox: "no",
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: loremIpsumParagraph,
		content: null,
		link: getLinkModal("Más información", "button3"),
		anchorID: null,
		fullHeight: false,
		alignment: "left",
		layout: "layout01",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ImageBanner/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ImageBanner/thumbnail@2x.png",
	},
};

export default schema;
