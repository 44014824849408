import { TextCarouselProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	themeSelector,
	loremIpsumParagraph,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<TextCarouselProps> = {
	schemaType: "module",
	component: "TextCarousel",
	category: "landings",
	displayName: "Text Carousel",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Quote",
					type: "TextField",
					key: "quote",
					hideable: true,
					isMockup: true,
				},
				{
					type: "UrlField",
					title: "URL",
					key: "url",
					placeholder: "https://",
					advanced: true,
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					title: "Typography",
					key: "typography",
					type: "RadioGroup",
					options: [
						{ name: "dmserif", value: "dmserif", title: "DM Serif Display" },
						{ name: "dmsans", value: "dmsans", title: "DM Sans" },
					],
					mandatory: true,
				},
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "TextCarousel",
		quote: loremIpsumParagraph,
		anchorID: null,
		subtheme: "inverse",
		animation: "none",
		typography: "dmserif",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TextCarousel/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TextCarousel/thumbnail@2x.png",
	},
};

export default schema;
