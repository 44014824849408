import { Schema } from "@griddo/core";

export const MODALITY: Schema.CategoryContentType = {
	dataPacks: ["PROGRAMS", "TESTIMONIAL"],
	title: "Modality",
	local: true,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
