import { DownloadableIconCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import DownloadableIconCard from "@schemas/components/DownloadableIconCard";
import {
	additional,
	anchorID,
	animation,
	content,
	detail,
	getThemeOptions,
	heading,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<DownloadableIconCollectionProps> = {
	schemaType: "module",
	component: "DownloadableIconCollection",
	category: "collection",
	displayName: "Downloadable Icon Collection",
	dataPacks: ["BRAND_SITE"],
	sectionList: { BasicTemplate: ["mainSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, isMockup: true, mandatory: false },
				{ ...detail },
				{ ...content },
				{
					type: "CheckGroup",
					title: "Select module options",
					key: "moduleOptions",
					options: [
						{
							title: "Search feature",
							name: "search",
						},
						{
							title: "Colors ",
							name: "colors",
						},
					],
				},
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["DownloadableIconCard"],
					contentType: "components",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "DownloadableIconCollection",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: null,
		content: null,
		elements: new Array(10)
			.fill(0)
			.map(() => ({ ...DownloadableIconCard.default })),
		link: { component: "Link" },
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/DownloadableIconCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/DownloadableIconCollection/thumbnail@2x.png",
	},
};

export default schema;
