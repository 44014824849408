//
// Griddo Components
//
// Every Component and their types must be imported and reexported.
// Yes, a barrel index :)

import { Component } from "@griddo/core";
import * as React from "react";

// Types
import type { AccordionProps } from "./Accordion";
import type { BackgroundCardProps } from "./BackgroundCard";
import type { BackgroundImageProps } from "./BackgroundImage";
import type { BackgroundVideoProps } from "./BackgroundVideo";
import type { BasicCardProps } from "./BasicCard";
import type { BasicFormProps } from "./BasicForm";
import type { BoxedCardProps } from "./BoxedCard";
import type { CardCarouselProps } from "./CardCarousel";
import type { ChartProps } from "./Chart";
import type { ColorCardProps } from "./ColorCard";
import type { ContactFormProps } from "./ContactForm";
import type { CypherCardProps } from "./CypherCard";
import type { DownloadableIconCardProps } from "./DownloadableIconCard";
import type { DownloadableLogoCardProps } from "./DownloadableLogoCard";
import type { DownloadFormProps } from "./DownloadForm";
import type { EventFormProps } from "./EventForm";
import type { FAQProps } from "./FAQ";
import type { HeroCarouselSlideProps } from "./HeroCarouselSlide";
import type { HighlightedImageCardProps } from "./HighlightedImageCard";
import type { HighlightedTextCardProps } from "./HighlightedTextCard";
import type { IconCardProps } from "./IconCard";
import type { ImageStepCollectionCardProps } from "./ImageStepCollectionCard";
import type { LandingFormProps } from "./LandingForm";
import type { LinkModalProps } from "./LinkModal";
import type { LogoCollectionCardProps } from "./LogoCollectionCard";
import type { MapProps } from "./Map";
import type { MilestoneSlideProps } from "./MilestoneSlide";
import type { SimpleStepCollectionCardProps } from "./SimpleStepCollectionCard";
import type { VideoProps } from "./Video";
import type { VideoPlaylistCardProps } from "./VideoPlaylistCard";

// Components
const Accordion = React.lazy(() => import("./Accordion"));
const BackgroundCard = React.lazy(() => import("./BackgroundCard"));
const BackgroundImage = React.lazy(() => import("./BackgroundImage"));
const BackgroundVideo = React.lazy(() => import("./BackgroundVideo"));
const BasicCard = React.lazy(() => import("./BasicCard"));
const BasicForm = React.lazy(() => import("./BasicForm"));
const BoxedCard = React.lazy(() => import("./BoxedCard"));
const CardCarousel = React.lazy(() => import("./CardCarousel"));
const Chart = React.lazy(() => import("./Chart"));
const ColorCard = React.lazy(() => import("./ColorCard"));
const ContactForm = React.lazy(() => import("./ContactForm"));
const CypherCard = React.lazy(() => import("./CypherCard"));
const DownloadableIconCard = React.lazy(() => import("./DownloadableIconCard"));
const DownloadableLogoCard = React.lazy(() => import("./DownloadableLogoCard"));
const DownloadForm = React.lazy(() => import("./DownloadForm"));
const EventForm = React.lazy(() => import("./EventForm"));
const FAQ = React.lazy(() => import("./FAQ"));
const HeroCarouselSlide = React.lazy(() => import("./HeroCarouselSlide"));
const HighlightedImageCard = React.lazy(() => import("./HighlightedImageCard"));
const HighlightedTextCard = React.lazy(() => import("./HighlightedTextCard"));
const IconCard = React.lazy(() => import("./IconCard"));
const ImageStepCollectionCard = React.lazy(
	() => import("./ImageStepCollectionCard")
);
const LandingForm = React.lazy(() => import("./LandingForm"));
const LinkModal = React.lazy(() => import("./LinkModal"));
const LogoCollectionCard = React.lazy(() => import("./LogoCollectionCard"));
const Map = React.lazy(() => import("./Map"));
const MilestoneSlide = React.lazy(() => import("./MilestoneSlide"));
const SimpleStepCollectionCard = React.lazy(
	() => import("./SimpleStepCollectionCard")
);
const Video = React.lazy(() => import("./Video"));
const VideoPlaylistCard = React.lazy(() => import("./VideoPlaylistCard"));

// Components for <GriddoComponent>
const components = {
	Accordion,
	BackgroundCard,
	BackgroundImage,
	BackgroundVideo,
	BasicCard,
	BasicForm,
	BoxedCard,
	CardCarousel,
	Chart,
	ColorCard,
	ContactForm,
	CypherCard,
	DownloadableIconCard,
	DownloadableLogoCard,
	EventForm,
	DownloadForm,
	FAQ,
	HeroCarouselSlide,
	HighlightedImageCard,
	HighlightedTextCard,
	IconCard,
	ImageStepCollectionCard,
	LandingForm,
	LinkModal,
	LogoCollectionCard,
	Map,
	MilestoneSlide,
	SimpleStepCollectionCard,
	Video,
	VideoPlaylistCard,
};

// Types for <GriddoComponent>
type ComponentProps =
	| AccordionProps
	| BackgroundCardProps
	| BackgroundImageProps
	| BackgroundVideoProps
	| BasicCardProps
	| BasicFormProps
	| BoxedCardProps
	| CardCarouselProps
	| ChartProps
	| ColorCardProps
	| ContactFormProps
	| CypherCardProps
	| DownloadableIconCardProps
	| DownloadableLogoCardProps
	| EventFormProps
	| DownloadFormProps
	| FAQProps
	| HeroCarouselSlideProps
	| IconCardProps
	| ImageStepCollectionCardProps
	| LandingFormProps
	| LinkModalProps
	| LogoCollectionCardProps
	| MapProps
	| MilestoneSlideProps
	| HighlightedImageCardProps
	| HighlightedTextCardProps
	| SimpleStepCollectionCardProps
	| VideoProps
	| VideoPlaylistCardProps;

// Component named export
export {
	Accordion,
	BackgroundCard,
	BackgroundImage,
	BackgroundVideo,
	BasicCard,
	BasicForm,
	BoxedCard,
	CardCarousel,
	Chart,
	ColorCard,
	ContactForm,
	CypherCard,
	DownloadableIconCard,
	DownloadableLogoCard,
	EventForm,
	DownloadForm,
	FAQ,
	HighlightedImageCard,
	HighlightedTextCard,
	IconCard,
	ImageStepCollectionCard,
	LandingForm,
	LinkModal,
	LogoCollectionCard,
	Map,
	MilestoneSlide,
	SimpleStepCollectionCard,
	Video,
	VideoPlaylistCard,
	// ...
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------- You shall not pass! ----------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------------------------------------------- Gandalf ----
// -----------------------------------------------------------------------------

// Griddo bundle export
export default components;

/**
 * `<GriddoComponent>`
 *
 * Render components with their Types dinamically and wrapped them with Griddo
 * functionalities.
 *
 * @example
 * <GriddoComponent component="BasicCard" {...basicCardProps} />
 *
 * Check out the [documentation](https://griddoio.notion.site/GriddoComponent-y-GriddoModule-en-la-instancia-bad16303bb4b49e0b696b62d1f6c40e9)
 */
export const GriddoComponent = (props: ComponentProps) => (
	<Component libComponents={components} {...props} />
);
