import { PeopleDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	anchorID,
	animation,
	content,
	getThemeOptions,
	heading,
	link,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<PeopleDistributorProps> = {
	schemaType: "module",
	component: "PeopleDistributor",
	category: "distributors",
	displayName: "People Distributor",
	dataPacks: ["TESTIMONIAL"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		ProgramDetail: ["mainSection"],
		EventDetail: ["mainContent"],
		ProgramLanding: ["mainSection"],
		Landing: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					type: "TextField",
					key: "supplementaryText",
					title: "Supplementary Text",
					placeholder: "Type a supplementary text",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					title: "People Cards",
					type: "ReferenceField",
					sources: [{ structuredData: "PEOPLE" }],
					key: "data",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/PeopleDistributor/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/PeopleDistributor/Layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/PeopleDistributor/Layouts/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/PeopleDistributor/Layouts/layout4.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt", "inverse"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
				{
					type: "RadioGroup",
					title: "Card orientation",
					key: "orientation",
					options: [
						{
							name: "horizontal",
							value: "horizontal",
							title: "Horizontal",
						},
						{
							name: "vertical",
							value: "vertical",
							title: "Vertical",
						},
					],
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "PeopleDistributor",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		supplementaryText: null,
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "PEOPLE", globalOperator: "OR" }],
			quantity: 4,
		},
		link: {
			component: "Link",
		},
		anchorID: null,
		layout: "layout3",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
		orientation: "horizontal",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/PeopleDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/PeopleDistributor/thumbnail@2x.png",
	},
};

export default schema;
