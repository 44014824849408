import { BasicHeroBrandSiteProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	anchorID,
	themeSelector,
	loremIpsumParagraph,
	animation,
	content,
	getThemeOptions,
	subtitle,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<BasicHeroBrandSiteProps> = {
	schemaType: "module",
	component: "BasicHeroBrandSite",
	category: "heros",
	displayName: "Basic Hero (Brand Site)",
	dataPacks: ["BRAND_SITE"],
	sectionList: { BasicTemplate: ["heroSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, isMockup: true, mandatory: false },
				{ ...subtitle },
				{ ...content },
				{
					type: "ComponentContainer",
					title: "Main link",
					key: "link1",
					whiteList: ["Link"],
					hideable: true,
				},
				{
					type: "ComponentContainer",
					title: "Secondary link",
					key: "link2",
					whiteList: ["Link"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "RadioGroup",
					key: "titleSize",
					title: "Title Size",
					mandatory: true,
					options: [
						{ name: "xl", value: "xl", title: "xl" },
						{ name: "l", value: "l", title: "l" },
						{ name: "m", value: "m", title: "m" },
					],
				},
				{ ...animation },
				{
					type: "UniqueCheck",
					key: "breadcrumbsDesktop",
					title: "Breadcrumbs Desktop/Tablet",
					options: [{ title: "Display in tablet and desktop" }],
				},
				{
					type: "UniqueCheck",
					key: "breadcrumbs",
					title: "Breadcrumbs Mobile",
					options: [{ title: "Display in mobile" }],
				},
				{ ...verticalSpacing },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt", "inverse"]),
				},
			],
		},
	],

	default: {
		component: "BasicHeroBrandSite",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		link1: { component: "Link" },
		link2: { component: "Link" },
		anchorID: null,
		titleSize: "l",
		animation: "none",
		breadcrumbsDesktop: false,
		breadcrumbs: false,
		verticalSpacing: "medium",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/BasicHeroBrandSite/thumbnail@1x.png",
		"2x": "/thumbnails/modules/BasicHeroBrandSite/thumbnail@2x.png",
	},
};

export default schema;
