import { AccordionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import InnerBasicText from "@schemas/modules/InnerBasicText";
import { heading } from "@schemas/presets";

const schema: Schema.Component<AccordionProps> = {
	schemaType: "component",
	component: "Accordion",
	displayName: "Accordion",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: false, isMockup: true },
				{
					type: "ComponentArray",
					title: "Content",
					key: "content",
					mandatory: true,
					whiteList: [
						"InnerBasicText",
						"InnerBasicContent",
						"InnerCardCollection",
					],
					contentType: "components",
				},
			],
		},
	],

	default: {
		component: "Accordion",
		title: {
			tag: "h3",
			content: "Title lorem ipsum",
		},
		content: [{ ...InnerBasicText.default }],
	},
};

export default schema;
