import { FeaturedBlockProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	verticalSpacing,
	themeSelector,
	subtitle,
	content,
	loremIpsumParagraph,
	animation,
	linkModal,
	getLinkModal,
} from "@schemas/presets";

const schema: Schema.Module<FeaturedBlockProps> = {
	schemaType: "module",
	component: "FeaturedBlock",
	category: "featured",
	displayName: "Featured Block",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...content,
				},
				{
					...heading,
					hideable: false,
					isMockup: true,
				},
				{
					...subtitle,
				},
				{
					...linkModal,
					hideable: false,
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					...anchorID,
				},
				{
					...themeSelector,
				},
				{
					...verticalSpacing,
				},
				{
					...animation,
				},
			],
		},
	],

	default: {
		component: "FeaturedBlock",
		type: "FeaturedBlock",
		content: "",
		subtitle: loremIpsumParagraph,
		title: { content: "Lorem Ipsum", tag: "h2" },
		link: getLinkModal("Más información", "button3"),
		subtheme: "accent",
		verticalSpacing: "medium",
		anchorID: null,
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedBlock/thumbnail@1x.png", //TODO fix thumbnail
		"2x": "/thumbnails/modules/FeaturedBlock/thumbnail@2x.png",
	},
};

export default schema;
