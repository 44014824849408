import { Schema } from "@griddo/core";

export const PROGRAM: Schema.PageContentType = {
	dataPacks: ["PROGRAMS", "TESTIMONIAL"],
	title: "Program",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["ProgramDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Abstract",
				from: "abstract",
				key: "abstract",
			},

			{
				title: "Image",
				type: "ImageField",
				key: "image",
				from: "image",
			},
			{
				type: "MultiCheckSelect",
				title: "Centers",
				placeholder: "Centers",
				from: "categories.centers",
				key: "centers",
				source: "CENTER",
			},
			{
				type: "MultiCheckSelect",
				title: "Schools",
				placeholder: "Schools",
				from: "categories.schools",
				key: "schools",
				source: "SCHOOL",
			},
			{
				type: "MultiCheckSelect",
				title: "Program type",
				placeholder: "Program type",
				from: "categories.programTypes",
				key: "programTypes",
				source: "PROGRAM_TYPE",
			},
			{
				type: "MultiCheckSelect",
				title: "Academic area",
				placeholder: "Academic area",
				from: "categories.academicAreas",
				key: "academicAreas",
				source: "ACADEMIC_AREA",
			},
			{
				type: "MultiCheckSelect",
				title: "Languages",
				placeholder: "Languages",
				from: "categories.languages",
				key: "languages",
				source: "LANGUAGE",
			},
			{
				type: "MultiCheckSelect",
				title: "Modalities",
				placeholder: "Modalities",
				from: "categories.modalities",
				key: "modalities",
				source: "MODALITY",
			},
			{
				type: "DateField",
				title: "Starts On",
				from: "intake",
				key: "intake",
			},
			{
				type: "TextField",
				title: "Duration",
				from: "duration",
				key: "duration",
			},
			{
				type: "TextField",
				title: "Seats",
				from: "seats",
				key: "seats",
			},
			{
				title: "Document to download",
				type: "ReferenceField",
				key: "data",
				from: "data",
				sources: [{ structuredData: "DOCUMENT" }],
				selectionType: ["manual"],
			},
		],
	},
};
