import { HighlightedHeaderProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	heading,
	verticalSpacing,
	content,
	loremIpsumParagraph,
	additional,
	themeSelector,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<HighlightedHeaderProps> = {
	schemaType: "module",
	component: "HighlightedHeader",
	category: "content",
	displayName: "Highlighted Header",
	dataPacks: ["BRAND_SITE"],
	sectionList: { BasicTemplate: ["mainSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ImageField",
					title: "Visual Content 1",
					key: "image1",
					mandatory: true,
					helptext:
						"Only visible in layout 2. Recommended minimum image size: 792x514",
				},
				{
					type: "ImageField",
					title: "Visual Content 2",
					key: "image2",
					hideable: true,
					helptext:
						"Only visible in layout 2. Recommended minimum image size: 792x514",
				},
				{ ...additional },
				{
					...heading,
					mandatory: false,
					hideable: true,
					isMockup: true,
				},
				{
					key: "subtitle",
					title: "Subtitle",
					type: "TextField",
					placeholder: "Type a subtitle",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
				{ ...content },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{
					type: "RadioGroup",
					key: "titleSize",
					title: "Title Size",
					mandatory: true,
					options: [
						{ name: "m", value: "m", title: "M" },
						{ name: "l", value: "l", title: "L" },
						{ name: "xl", value: "xl", title: "XL" },
					],
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "HighlightedHeader",
		image1: undefined,
		image2: undefined,
		additional: "Info",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		subtitle: null,
		content: loremIpsumParagraph,
		anchorID: null,
		subtheme: "default",
		titleSize: "xl",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HighlightedHeader/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HighlightedHeader/thumbnail@2x.png",
	},
};

export default schema;
