import BasicTemplate from "./BasicTemplate";
import Error404 from "./Error404";
import EventDetail from "./EventDetail";
import EventsList from "./EventsList";
import Landing from "./Landing";
import NewsDetail from "./NewsDetail";
import NewsList from "./NewsList";
import PressReleasesDetail from "./PressReleasesDetail";
import PressReleasesList from "./PressReleasesList";
import ProgramDetail from "./ProgramDetail";
import ProgramLanding from "./ProgramLanding";
import ProgramsList from "./ProgramsList";
import SearchResults from "./SearchResults";

export default {
	BasicTemplate,
	Error404,
	EventDetail,
	EventsList,
	Landing,
	NewsDetail,
	NewsList,
	PressReleasesDetail,
	PressReleasesList,
	ProgramDetail,
	ProgramLanding,
	ProgramsList,
	SearchResults,
};
