import { StickyTabsProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Module<StickyTabsProps> = {
	schemaType: "module",
	component: "StickyTabs",
	displayName: "Sticky Tabs",
	singleInstance: true,

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Elements",
					key: "elements",
					whiteList: ["SimpleLink"],
					contentType: "components",
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "StickyTabs",
		elements: [],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/StickyTabs/thumbnail@1x.png",
		"2x": "/thumbnails/modules/StickyTabs/thumbnail@2x.png",
	},
};

export default schema;
