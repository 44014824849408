import { LogoThemeCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Component<LogoThemeCardProps> = {
	schemaType: "component",
	component: "LogoThemeCard",
	displayName: "Logo Theme Card",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Bg Color",
					type: "ColorPicker",
					key: "bgColor",
					colors: ["#F3F3F7", "#1510F7", "#010E21"],
					mandatory: true,
				},
				{
					title: "Logo type",
					type: "ConditionalField",
					key: "type",
					mandatory: true,
					options: [
						{
							name: "default",
							value: "default",
							title: "Default (project logos)",
						},

						{
							name: "custom",
							value: "custom",
							title: "Custom (upload)",
						},
					],
					fields: [
						{
							condition: "default",
							title: "Select logo",
							type: "Select",
							key: "logo",
							placeholder: "Select an logo",
							options: [
								{ value: "logoMainHorizontalBn", label: "Main" },
								{
									value: "logoMainHorizontal",
									label: "Main color",
								},
								{
									value: "logoMainHorizontalInverse",
									label: "Main inverse",
								},
								{
									value: "logoSecondaryHorizontal",
									label: "Secondary color",
								},
							],
						},
						{
							condition: "custom",
							title: "Logo",
							type: "ImageField",
							key: "logoImg",
						},
					],
				},
				{ ...heading, isMockup: true, mandatory: false },
				{
					title: "Hexadecimal code",
					type: "TextField",
					key: "hexadecimal",
					hideable: true,
				},
				{
					title: "RGB code",
					type: "TextField",
					key: "rgb",
					hideable: true,
				},
				{
					title: "CMYK code",
					type: "TextField",
					key: "cmyk",
					hideable: true,
				},
				{
					title: "PANTONE",
					type: "TextField",
					key: "pantone",
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "LogoThemeCard",
		bgColor: "#F3F3F7",
		type: "default",
		logo: "logoMainHorizontalInverse",
		title: { content: "Lorem ipsum", tag: "h4" },
		hexadecimal: "#F3F3F7",
		rgb: "(243, 243, 247)",
		cmyk: "C2 M2 Y0 K3",
		pantone: null,
	},
};

export default schema;
