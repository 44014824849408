import { TypographyPreviewerProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	content,
	getThemeOptions,
	loremIpsumParagraph,
	subtitle,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<TypographyPreviewerProps> = {
	schemaType: "module",
	component: "TypographyPreviewer",
	category: "interactive",
	displayName: "Typography previewer",
	dataPacks: ["BRAND_SITE"],
	sectionList: { BasicTemplate: ["mainSection"] },

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Typography",
					key: "typography",
					type: "RadioGroup",
					options: [
						{ name: "dmserif", value: "dmserif", title: "DM Serif Display" },
						{ name: "dmsans", value: "dmsans", title: "DM Sans" },
					],
					mandatory: true,
				},
				{ ...subtitle },
				{ ...content },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "TypographyPreviewer",
		typography: "dmserif",
		subtitle: loremIpsumParagraph,
		content: null,
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TypographyPreviewer/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TypographyPreviewer/thumbnail@2x.png",
	},
};

export default schema;
