import { Schema } from "@griddo/core";
import { getThemeOptions, themeSelector } from "@schemas/presets";

const schema: Schema.Footer = {
	schemaType: "module",
	displayName: "Footer (small)",
	component: "FooterLanding",
	type: "footer",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Footer name",
					key: "title",
					type: "TextField",
					placeholder: "Type a title",
					mandatory: true,
				},
				{
					title: "Text carousel",
					type: "TextField",
					key: "textCarousel",
					hideable: true,
				},
				{
					type: "UrlField",
					title: "URL carousel",
					key: "url",
					placeholder: "https://",
					advanced: true,
					hideable: true,
				},
				{
					title: "Show Social Media",
					type: "RadioGroup",
					key: "showSocialMedia",
					mandatory: true,
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
				{
					title: "Copyright",
					type: "TextField",
					key: "copyright",
					placeholder: "Type a copyright",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					title: "Set as default",
					key: "setAsDefault",
					options: [
						{
							title: "Set as Footer default",
						},
					],
				},
				{
					title: "Legal pages",
					key: "legalPages",
					type: "AsyncSelect",
					entity: "menu_containers",
					helptext: "Select the site menu. It must first be created.",
					mandatory: true,
				},
				{ ...themeSelector, options: getThemeOptions(["default", "inverse"]) },
			],
		},
	],

	default: {
		component: "FooterLanding",
		type: "footer",
		title: "New Footer",
		setAsDefault: false,
		subtheme: "inverse",
		showSocialMedia: false,
		copyright: "© 2024 Universidad UTEF",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FooterLanding/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FooterLanding/thumbnail@2x.png",
	},
};

export default schema;
