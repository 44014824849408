import { Schema } from "@griddo/core";

export const CENTER: Schema.SimpleContentType = {
	dataPacks: ["TESTIMONIAL", "NEWS", "EVENTS", "PROGRAMS"],
	title: "Centers",
	local: true,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "shortTitle",
				title: "Short Title",
				placeholder: "Type a short title",
				type: "TextField",
			},
			{
				key: "order",
				title: "Order",
				helptext:
					"A number (greater or equal than 10) indicating the order of this center in the program template filter",
				type: "NumberField",
				min: 10,
				indexable: true,
			},
			{
				key: "logo",
				title: "Logo",
				type: "ImageField",
			},
		],
	},
};
