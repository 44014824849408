import { StepCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import SimpleStepCollectionCard from "@schemas/components/SimpleStepCollectionCard";
import {
	additional,
	anchorID,
	heading,
	verticalSpacing,
	themeSelector,
	loremIpsumParagraph,
	subtitle,
	content,
	getThemeOptions,
	animation,
	link,
} from "@schemas/presets";

const schema: Schema.Module<StepCollectionProps> = {
	schemaType: "module",
	displayName: "Steps Collection",
	category: "collection",
	component: "StepCollection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{
					...heading,
					isMockup: true,
					mandatory: false,
				},

				{
					...subtitle,
				},

				{
					...content,
				},

				{
					title: "Steps",
					type: "ComponentArray",
					key: "steps",
					whiteList: ["SimpleStepCollectionCard", "ImageStepCollectionCard"],
					contentType: "components",
					maxItems: 10,
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [
				{
					...anchorID,
				},
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/StepCollection/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/StepCollection/Layouts/layout02.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},

				{
					...verticalSpacing,
				},

				{
					...animation,
				},
			],
		},
	],

	default: {
		component: "StepCollection",
		additional: null,
		title: { content: "Lorem Ipsum", tag: "h2" },
		subtitle: loremIpsumParagraph,
		content: null,
		steps: [
			{ ...SimpleStepCollectionCard.default },
			{ ...SimpleStepCollectionCard.default },
			{ ...SimpleStepCollectionCard.default },
		],
		link: { component: "Link" },
		anchorID: null,
		layout: "layout01",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/StepCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/StepCollection/thumbnail@2x.png",
	},
};

export default schema;
