import { Schema } from "@griddo/core";
import { detail, heading } from "@schemas/presets";

export const STUDY_PLAN: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS"],
	title: "Study plan",
	local: true,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Label",
				placeholder: "Type a label",
				type: "TextField",
				mandatory: true,
				helptext: "Used in the editor.",
			},
			{
				key: "years",
				title: "Years",
				name: "Year",
				type: "ArrayFieldGroup",
				arrayType: "dropDown",
				mandatory: true,
				fields: [
					{
						...heading,
						default: { tag: "h3", content: "Lorem ipsum" },
						mandatory: false,
						isMockup: true,
					},
					{ ...detail },
					{
						key: "semesters",
						title: "Semesters",
						name: "Semester",
						type: "ArrayFieldGroup",
						arrayType: "dropDown",
						mandatory: true,
						fields: [
							{
								key: "semesterTitle",
								title: "Semester title",
								type: "TextField",
								mandatory: true,
								humanReadable: true,
							},
							{
								key: "creditsTitle",
								title: "Credits title",
								type: "TextField",
								humanReadable: true,
							},
							{
								key: "subjects",
								title: "Subjects",
								name: "Subject",
								type: "ArrayFieldGroup",
								arrayType: "dropDown",
								mandatory: true,
								fields: [
									{
										...heading,
										default: { tag: "h4", content: "Lorem ipsum" },
										mandatory: true,
									},
									{
										key: "credits",
										title: "Credits",
										type: "TextField",
										mandatory: true,
									},
									{
										key: "document",
										title: "Document",
										type: "FileField",
									},
								],
							},
						],
					},
				],
			},
		],
	},
};
