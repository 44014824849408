import { MilestonesProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import MilestoneSlide from "@schemas/components/MilestoneSlide";
import {
	additional,
	anchorID,
	animation,
	content,
	getThemeOptions,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<MilestonesProps> = {
	schemaType: "module",
	component: "Milestones",
	displayName: "Milestones",
	category: "collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...content },
				{
					type: "ComponentArray",
					title: "Milestones",
					key: "elements",
					whiteList: ["MilestoneSlide"],
					contentType: "components",
					mandatory: true,
					maxItems: 12,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt", "inverse"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "Milestones",
		additional: null,
		title: { content: "Lorem ipsum", tag: "h2" },
		content: "Lorem ipsum",
		elements: [
			{ ...MilestoneSlide.default },
			{ ...MilestoneSlide.default },
			{ ...MilestoneSlide.default },
		],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Milestones/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Milestones/thumbnail@2x.png",
	},
};

export default schema;
