import { LinkModalProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<LinkModalProps> = {
	schemaType: "component",
	displayName: "Link Modal",
	component: "LinkModal",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "LinkField",
					title: "",
					key: "link",
					whiteList: ["ModalForm", "InnerWidget"],
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					title: "Variant",
					key: "variant",
					type: "VisualUniqueSelection",
					columns: 3,
					options: [
						{
							value: "button1",
							img: "/thumbnails/components/Link/button1@2x.png",
						},
						{
							value: "button3",
							img: "/thumbnails/components/Link/button3@2x.png",
						},
						{
							value: "button4",
							img: "/thumbnails/components/Link/button4@2x.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "LinkModal",
		link: {
			text: "",
			linkType: "url",
			modal: {
				modalForm: { component: "ModalForm" },
				innerWidget: { component: "InnerWidget" },
			},
		},
		variant: "button1",
	},
};

export default schema;
