import { EVENT } from "./EVENT";
import { NEWS } from "./NEWS";
import { PRESS_RELEASES } from "./PRESS_RELEASES";
import { PROGRAM } from "./PROGRAM";

export default {
	EVENT,
	NEWS,
	PRESS_RELEASES,
	PROGRAM,
};
