import { FeaturedContentProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	verticalSpacing,
	themeSelector,
	content,
	loremIpsumParagraph,
	animation,
	getThemeOptions,
	linkModal,
	getLinkModal,
} from "@schemas/presets";

const schema: Schema.Module<FeaturedContentProps> = {
	schemaType: "module",
	component: "FeaturedContent",
	category: "featured",
	displayName: "Featured Content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					key: "media",
					title: "Media",
					whiteList: ["Image", "Video"],
					hideable: true,
				},
				{
					...heading,
					mandatory: false,
					isMockup: true,
				},
				{
					...content,
				},
				{
					...linkModal,
					title: "Main Link",
					key: "mainLink",
					hideable: false,
					mandatory: true,
				},
				{
					...linkModal,
					title: "Secondary Link",
					key: "secondaryLink",
					hideable: true,
				},
				{
					key: "facts",
					title: "Facts",
					name: "Facts",
					type: "ArrayFieldGroup",
					arrayType: "dropDown",
					mandatory: true,
					fields: [
						{
							title: "Fact",
							type: "TextField",
							key: "label",
							hideable: true,
							isMockup: true,
							humanReadable: true,
						},
					],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					...anchorID,
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{
					...verticalSpacing,
				},
				{
					...animation,
				},
			],
		},
	],

	default: {
		component: "FeaturedContent",
		media: {
			image: { component: "Image" },
			video: { component: "Video" },
		},
		title: { content: "Lorem Ipsum", tag: "h2" },
		content: loremIpsumParagraph,
		mainLink: getLinkModal("¿Te llamamos?", "button3"),
		secondaryLink: getLinkModal("Saber más", "button4"),
		facts: [],
		subtheme: "default",
		verticalSpacing: "medium",
		anchorID: null,
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedContent/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FeaturedContent/thumbnail@2x.png",
	},
};

export default schema;
