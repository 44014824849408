import { FAQCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import FAQ from "@schemas/components/FAQ";
import {
	anchorID,
	heading,
	link,
	verticalSpacing,
	themeSelector,
	getThemeOptions,
	subtitle,
	content,
	additional,
	animation,
} from "@schemas/presets";

const schema: Schema.Module<FAQCollectionProps> = {
	schemaType: "module",
	component: "FAQCollection",
	category: "collection",
	displayName: "FAQs Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					title: "Frequently Asked Questions",
					type: "ComponentArray",
					key: "questions",
					whiteList: ["FAQ"],
					contentType: "components",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "FAQCollection",
		additional: null,
		title: { content: "Lorem Ipsum", tag: "h3" },
		subtitle: "Lorem ipsum subtitle",
		content: null,
		questions: [{ ...FAQ.default }, { ...FAQ.default }, { ...FAQ.default }],
		link: {
			component: "Link",
		},
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FAQCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FAQCollection/thumbnail@2x.png",
	},
};

export default schema;
